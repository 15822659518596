import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import AppStoreButton from '../AppStoreButton';
import cand from '../../assets/homeImages/candidate.jpg';
import empl from '../../assets/homeImages/employer.jpg';
import rto from '../../assets/homeImages/rto.jpg';
import esp from '../../assets/homeImages/esp.jpg';
import { Button } from '@material-ui/core';
import Styles from '../../styles';
import FAQData from '../../JSONdata/FAQData.json';
import FAQQuestions from '../FAQQuestions';

const candidateQuestions = FAQData.candidateQuestions;
const employerQuestions = FAQData.employerQuestions;
const espQuestions = FAQData.espQuestions;
const rtoQuestions = FAQData.rtoQuestions;
const colours = new Styles();

const useStyles = makeStyles((theme) => ({
    button: {
      border: '3px solid',
      backgroundColor: colours.appBarColor,
      borderColor: colours.green,
      color: 'white',
      padding: 5,
      paddingLeft: 25,
      paddingRight: 25,
      alignSelf: 'flex-end',
      textTransform: 'none',
      fontSize: 20,
      textAlign: 'center'
    },
  
  }));

export default function HeaderHomeType(props) {

    const styles = useStyles();

    function openPopup() {
        window.open("https://app.aible.app/","AiBLE Job Search","width=350,height=600")
    }


    var faq = <FAQQuestions questions={candidateQuestions}/>;
    var buttonTitle = "Using your computer?";
    var button = <div>
        <Button
            variant='contained'
            className={styles.button}
            onClick={openPopup}
            >
            CLICK HERE
        </Button>
        <div style ={{fontSize:11}}>(You may need to allow popups for this site in your browser)</div>
    </div>


    var usingPhone = <div>
        <div style={{fontSize:30}}>
                Using your phone?<br/>Download AiBLE
            </div><br/>
            <AppStoreButton type = 'google' width = '140px'/><br/>
            <AppStoreButton type = 'apple' width = '140px'/>
    </div>

    if (props.type === 'empl') {
        faq = <FAQQuestions questions={employerQuestions}/>;
    } else if (props.type === 'rto') {
        faq = <FAQQuestions questions={rtoQuestions}/>;
        buttonTitle = <div>Register your Training<br/>Organisation</div>
        button = <Button
            variant='contained'
            className={styles.button}
            component={Link}
            to={'/rtos'}
            >
            CLICK HERE
        </Button>
        usingPhone = <div></div>
    } else if (props.type === 'esp') {
        faq = <FAQQuestions questions={espQuestions}/>;
        buttonTitle = <div>Register your ESP and<br/>Connect to AiBLE for<br/>Free</div>
        button = <Button
            variant='contained'
            className={styles.button}
            component={Link}
            to={'/espPortal'}
            >
            CLICK HERE
        </Button>
        usingPhone = <div></div>
    }

  var width = window.innerWidth;

  var direction = 'row';
  var mobile = 6;
  var imgWidth = '100%';
  

  if (width < 713) {
    direction = 'column';
    mobile = 12;
    imgWidth = '80%'
    faq = <div/>
  }



    return <Grid item  style={{width:'100%'}}>
        <Box boxShadow={3} style={{ backgroundColor: colours.charcoalGrey, padding: 20, width: '100%', textAlign: 'center'}}>
        <Grid item container direction = {direction} justify='center'>

            <Grid item xs={mobile}>
                {faq}
            </Grid>

            <Grid item container direction = "column" xs = {mobile-1} alignItems='center'>

                <Grid item>
                    <div style={{fontSize:30, paddingTop:60}}>{buttonTitle}</div>
                </Grid>
                <Grid item style = {{paddingTop:60}}>
                    {button}
                </Grid>

                <Grid item style = {{paddingTop:100}}>
                    {usingPhone}
                </Grid>

            </Grid>

        </Grid>
    </Box> </Grid>;


}