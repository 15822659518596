import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { loginUser, useAuthState, useAuthDispatch } from '../Context';
import TopBar from '../elements/TopBar';

const LoginScreen = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const dispatch = useAuthDispatch();
  const { loading, errorMessage } = useAuthState();

  const handleLogin = async (e) => {
    e.preventDefault();

    let payload = { email, password };
    //console.log(
    //  `Payload email is ${payload.email} and password ${payload.password}`
    //);
    try {
      let response = await loginUser(dispatch, payload);
      //console.log(`response: ${response}`);
      console.log(JSON.stringify(response));
      if (!response || !response.access_token) return; //Incase response is totally undefined.
      console.log('Got an access token');
      history.push('/'); // TODO: need to go to either jobs or ? depending on employer or candidate
    } catch (error) {
      console.log(error); // TODO: need to do something with this.
    }
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  const classes = useStyles();

  return (
    <>
      <TopBar />
      <Container component='main' maxWidth='xs'>
        <div className={{ width: 200 }}>
          <h1>Login Page</h1>
          {errorMessage ? <p>Login Failed, please try again</p> : null}
          <form>
            <div>
              <div>
                <label htmlFor='email'>Username</label>
                <input
                  type='text'
                  id='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
              </div>
              <div>
                <label htmlFor='password'>Password</label>
                <input
                  type='password'
                  id='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                />
              </div>
            </div>
            <Button onClick={handleLogin} disabled={loading}>
              login
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
};

export default LoginScreen;
