import React from 'react';
import appstore from '../assets/app-store-badge.svg';
import playstore from '../assets/google-play-badge.svg';


export default function AppStoreButton(props) {

    var google = "https://play.google.com/store/apps/details?id=app.aible.android3";
    var apple = "https://apps.apple.com/au/app/aible-job-search/id1466939787";
    var url = google;
    var img = playstore;

    var styles;

    if (props.height !== undefined) {
        styles = {height:props.height};
    } else {
        styles = {width:props.width};
    }


    if (props.type === 'apple') {
        url = apple;
        img = appstore;
    }


    return <a href={url}><img src={img} alt='Link to store' style={styles} /></a>


    


}