import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import PageHeader from '../elements/PageHeader';
import TopBar from '../elements/TopBar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const profiles = [
    {
      id: 1,
      image_url: require("../assets/meetTheTeam/narelle.png"),
      profile_info: "Narelle Priestley is the Managing Director/Owner of Heimdall Services Pty Ltd. Narelle leads an Australian team for Research, Application Development and Data Mining for the development of AiBLE. Narelle has been in the ICT industry for over 30 years in senior sales director roles responsible for managing the relationships between the companies and their enterprise customers covering all aspects of sales, commercial and service delivery. Narelle wants AiBLE to transform recruitment to include everyone by removing bias and discrimination."
    },
    //{
    //  id: 2,
    //  image_url: require("../assets/meetTheTeam/rachel.png"),
    //  profile_info: "Rachel Breman is the Head of Research at Heimdall Services Pty Ltd and has spent the past twenty years of her research career working in the fields of social research and policy both in Australia and in the United Kingdom. Rachel was the Head of Research, Policy and Advocacy at Baptcare and has held senior roles at The Social Research Centre in Melbourne and the National Centre for Social Research in London where she conducted research projects for State and Federal Governments and numerous academic institutions. Rachel is passionate about shining a light on vulnerable populations in society and wants AiBLE to help people on the margins to achieve their full potential."
    //},
    {
        id: 3,
        image_url: require("../assets/meetTheTeam/adam-profile-image.jpg"),
        profile_info: 'Adam Crow has an Electrical and Computer Systems Engineering Degree (honours) from Monash University and a master’s degree in Artificial Intelligence and Advanced Networking. With over 30 years extensive experience in industry and start-ups, Adam is an accomplished innovative architect, designer, and team leader. Member of IEEE.'
    },
    {
        id: 4,
        image_url: require("../assets/meetTheTeam/mkaan.png"),
        profile_info: 'Mohammad Kaan has over thirty years of experience in IT Infrastructure, server development and management services. He has also pioneered the Opensource and Public Domain Software provisions for small to medium business in Australia. His current primary focus is on DevOps and Cloud Technologies.'
    },
    {
        id: 5,
        image_url: require("../assets/meetTheTeam/joe.jpg"),
        profile_info: "Joseph Maher is currently studying software engineering at Swinburne University, and has been working with Adam Crow for the past year at Crowtech and Gada Technologies. He has experience in mobile software development and computer aided design for 3D printing."
    },
    {
        id: 6,
        image_url: require("../assets/meetTheTeam/dan.jpg"),
        profile_info: "Daniel Mills is a student studying for a Bachelor of Computer Science at RMIT. He has experience in web development and is a hobbyist games programmer and musician. His current focus is on website and mobile software development."
    }
];

export default function MeetTheTeam(props) {

    return (
        <div>
            <TopBar child = {<PageHeader colour = {props.colour} title = {"MEET THE TEAM"}/>}/>
            <br/>
            <List style={{justifyContent:'center', alignItems:'center'}}>
                {
                    profiles.map((item) => 
                        <Profile key = {item.id} profile_info={item.profile_info} image_url = {item.image_url}/>
                    )
                }
            </List>
        </div>
    );

}

function Profile(props) {

    var width = window.innerWidth;
    var padding = (width-990)/2;
    var xs = 2;
    var direction = "row";
    if (padding < 0) padding = 0;
    if (width < 713) {
        padding = 10;
        xs = 12;
        direction = "column";
    }

    return(
        <div style={{paddingLeft:padding,paddingRight:padding}}>
            <ListItem key = {props.key} style = {{paddingBottom:20,paddingTop:20}}>
                <Grid container direction = {direction} justify = "center" alignItems = "center">
                    <Grid item xs = {xs}>
                        <img src = {props.image_url} style = {{width:100}} alt = "the person in question"/>
                    </Grid>
                    <Grid item xs = {10}>
                        <span>{props.profile_info}</span>
                    </Grid>
                </Grid>
                
            </ListItem>
            <Divider/>
        </div>
    );
}