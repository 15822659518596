import React from 'react';
import Box from '@material-ui/core/Box';
import Styles from '../styles';

function PageHeader(props) {

    const colours = new Styles();
    var colour = props.colour;
    const title = props.title;

    if (colour === undefined) colour = colours.lightblue;

    var width = window.innerWidth;
    var fontSize = 19;
    if (width < 713) {
        fontSize = 15;
    }

    return (
        <div>
            <Box boxShadow={3} style={{flexGrow:1,color:"white",backgroundColor:colour,textAlign:"center",padding:1}}>
                <p style = {{fontWeight:"bold",fontSize:fontSize}}>{title}</p>
            </Box>
        </div>
    );

}

export default PageHeader;