import React from 'react';
import {
    HashRouter,
    Switch,
    Route,
} from "react-router-dom";
import BottomBar from './elements/BottomBar';
import ComingSoon from './Pages/ComingSoon';
import FAQ from './Pages/FAQ';
import Partners from './Pages/Partners';
import FAQData from './JSONdata/FAQData.json';
import RTOHome from './Pages/RTOHome';
import Brochure from './Pages/Brochure';
import Support from './Pages/Support';
import Home from './Pages/Home';
import Jobs from './Pages/Jobs';
import WebApp from './Pages/WebApp';
import WhyAible from './Pages/WhyAible';
import Stories from './Pages/Stories';
import Search from './Pages/Search';
import TrainingHome from './Pages/TrainingHome';
import TrainingQuiz from './Pages/TrainingQuiz';
import MeetTheTeam from './Pages/MeetTheTeam';
import News from './Pages/News';
import LoginScreen from './Pages/LoginScreen';
import OurStory from './Pages/OurStory';
import RoadToLaunch from './Pages/RoadToLaunch';
import TestAppFrame from './Pages/TestAppFrame';
import PaymentPage from './Pages/PaymentPage';
import ESPPage from './Pages/ESPPage';
import CorpPage from './Pages/CorpPage';
import CharityPage from './Pages/CharityPage';
import ESPRTOApp from './Pages/ESPRTOApp';
import HeaderHomeDefault from './elements/headerHomes/HeaderHomeDefault';
import HeaderHomeBase from './elements/headerHomes/HeaderHomeBase';
import HeaderHomeType from './elements/headerHomes/HeaderHomeType';
import Styles from './styles';

export default function MainRouter(props) {



    const candidateQuestions = FAQData.candidateQuestions;
    const employerQuestions = FAQData.employerQuestions;
    const espQuestions = FAQData.espQuestions;
    const rtoQuestions = FAQData.rtoQuestions;


    const colours = new Styles();
    return (
        <div style={{ backgroundColor: colours.charcoalgrey}}>
            <HashRouter>
                <Switch>
                    <Route path="/jobs">
                        <ComingSoon title="LOGIN TO AiBLE AND SEE YOUR % MATCH AGAINST EVERY JOB" colour={colours.green}/>
                        <BottomBar />
                    </Route>

                    <Route path="/partners">
                        <Partners />
                        <BottomBar />
                    </Route>

                    <Route path="/candidateFAQ">
                        <FAQ title='JOB CANDIDATES' colour={colours.green} questions={candidateQuestions} />
                        <BottomBar />
                    </Route>

                    <Route path="/employerFAQ">
                        <FAQ title='EMPLOYERS' colour={colours.lightblue} questions={employerQuestions} />
                        <BottomBar />
                    </Route>

                    <Route path="/espFAQ">
                        <FAQ title='EMPLOYMENT SUPPORT PROVIDERS' colour={colours.blue} questions={espQuestions} />
                        <BottomBar />
                    </Route>

                    <Route path="/rtoFAQ">
                        {/*<FAQ title="REGISTERED TRAINING ORGANISATIONS" colour={colours.purple} questions={rtoQuestions} />*/}
                        <ComingSoon title='REGISTERED TRAINING ORGANISATIONS' colour={colours.purple} />
                        <BottomBar />
                    </Route>

                    <Route path="/rtos">
                        {/*<RTOHome title='REGISTERED TRAINING ORGANISATIONS' colour={colours.purple} questions={rtoQuestions} />*/}
                        <ComingSoon title='REGISTERED TRAINING ORGANISATIONS' colour={colours.purple} />
                        <BottomBar />
                    </Route>

                    <Route path="/rtoform">
                        <ComingSoon colour={colours.purple} title="REGISTERED TRAINING ORGANISATIONS" />
                        <BottomBar />
                    </Route>

                    <Route path="/support">
                        <Support />
                        <BottomBar />
                    </Route>

                    <Route path="/stories">
                        <Stories />
                        <BottomBar />
                    </Route>

                    <Route path="/app">
                        <WebApp />
                        <BottomBar />
                    </Route>

                    <Route path='/why'>
                        <WhyAible />
                        <BottomBar />
                    </Route>

                    <Route path='/brochure'>
                        <Brochure />
                        <BottomBar />
                    </Route>

                    <Route path='/search'>
                        <Search />
                        <BottomBar />
                    </Route>

                    <Route path='/trainhome'>
                        <TrainingHome colour={colours.purple} />
                        <BottomBar />
                    </Route>

                    <Route path='/trainquizeveryone'>
                        <TrainingQuiz colour={colours.purple} course="COR_DISABILITY" title="DISABILITY AWARENESS - FOR EVERYONE" />
                        <BottomBar />
                    </Route>

                    <Route path='/trainquizemployer'>
                        <TrainingQuiz colour={colours.purple} course="COR_EMPLOYER" title="DISABILITY AWARENESS - FOR EMPLOYERS" />
                        <BottomBar />
                    </Route>

                    <Route path='/meettheteam'>
                        <MeetTheTeam colour={colours.green} />
                        <BottomBar />
                    </Route>

                    <Route path='/ourstory'>
                        <OurStory colour={colours.purple} />
                        <BottomBar />
                    </Route>

                    <Route path='/espportal'>
                        <ESPPage colour={colours.lightblue}/>
                        <BottomBar />
                    </Route>

                    <Route path='/corpportal'>
                        <CorpPage colour={colours.purple}/>
                        <BottomBar />
                    </Route>

                    <Route path='/charityportal'>
                        <CharityPage colour={colours.lightblue}/>
                        <BottomBar />
                    </Route>

                    <Route path='/news'>
                        <News colour={colours.blue} />
                        <BottomBar />
                    </Route>

                    <Route path='/roadtolaunch'>
                        <RoadToLaunch colour={colours.blue} />
                        <BottomBar />
                    </Route>

                    <Route path='/signin'>
                        <LoginScreen />
                        <BottomBar />
                    </Route>

                    <Route path = '/flutterwebapp'>
                        <TestAppFrame/>
                        <BottomBar/>
                    </Route>

                    <Route path = '/paymentTest'>
                        <PaymentPage/>
                    </Route>

                    <Route path = '/espAppRegister'>
                        <ESPRTOApp path = '/espRegister'/>
                        <BottomBar/>
                    </Route>
                    <Route path = '/espAppLogin'>
                        <ESPRTOApp path = '/espLogin'/>
                        <BottomBar/>
                    </Route>
                    <Route path = '/corpAppRegister'>
                        <ESPRTOApp path = '/corpRegister'/>
                        <BottomBar/>
                    </Route>
                    <Route path = '/corpAppLogin'>
                        <ESPRTOApp path = '/corpLogin'/>
                        <BottomBar/>
                    </Route>
                    <Route path = '/charityAppRegister'>
                        <ESPRTOApp path = '/charityRegister'/>
                        <BottomBar/>
                    </Route>
                    <Route path = '/charityAppLogin'>
                        <ESPRTOApp path = '/charityLogin'/>
                        <BottomBar/>
                    </Route>

                    <Route path ='/webapp'>
                        <iframe 
                        src={"https://app.aible.app"+"?date="+Date.now().toString()} 
                        name = {Date.now().toString()}
                        style={{
                            position:"fixed", 
                            top:0, 
                            left:0, 
                            bottom:0, 
                            right:0, 
                            width:"100%", 
                            height:"100%", 
                            border:"none", 
                            margin:0, 
                            padding:0,
                            overflow:"hidden",
                            zIndex:999999
                        }}/>
                    </Route>

                    <Route path="/homecand">
                        <Home child={<HeaderHomeBase child = {<HeaderHomeType type = "cand"/>} />}/>
                        <BottomBar/>
                    </Route>
                    <Route path="/homeempl">
                        <Home child={<HeaderHomeBase child = {<HeaderHomeType type = "empl"/>} />}/>
                        <BottomBar/>
                    </Route>
                    <Route path="/homeesp">
                        <Home child={<HeaderHomeBase child = {<HeaderHomeType type = "esp"/>} />}/>
                        <BottomBar/>
                    </Route>
                    <Route path="/homerto">
                        <Home child={<HeaderHomeBase child = {<HeaderHomeType type = "rto"/>} />}/>
                        <BottomBar/>
                    </Route>

                    <Route path="/">
                        <Home child={<HeaderHomeBase child={<HeaderHomeDefault/>} />}/>
                        <BottomBar />
                    </Route>

                </Switch>

            </HashRouter>
        </div>

    );


}
