import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TypingElement from '../Typing';
import TypingQuotes from '../../JSONdata/TypingQuotes.json';
import pitch from '../../assets/audio/pitch.mp3';
import AudioPlayer from '../../widgets/AudioPlayer';
import Ellipse from '../../assets/backgroundEllipse.svg';
import Styles from '../../styles';

const useStyles = makeStyles((theme) => ({
  typing: {
    padding: 10,
    paddingLeft: 30,
  },
  logoNoSlogan: {
    width: 180,
  },
  jobactive: {
    padding: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  button: {
    border: '3px solid',
    backgroundColor: '#FFFFFF',
    borderColor: '#00FF99',
    padding: 5,
    paddingLeft: 25,
    paddingRight: 25,
    alignSelf: 'flex-end',
    textTransform: 'none',
    fontSize: 20,
  },
  AiBLE: {
    font: 'Arial',
    fontWeight: 'bold',
  },
}));



export default function HeaderHomeBase(props) {
  const colours = new Styles();
  const styles = useStyles();
  var width = window.innerWidth;
  var justify = 'center';
  var align = 'flex-start';

  var direction = 'row';
  var stlogow = '5vw';
  var sttextw = '60%';
  var background = `url(${Ellipse})`;
  var mobile = 0;
  

  if (width < 713) {
    justify = 'center';
    align = 'center';
    direction = 'column';
    stlogow = '40%';
    sttextw = '80%';
    mobile = 12;
    background = "";
  }

  return (
    <Grid
      item
      container
      direction={direction}
      alignItems={align}
      justify={justify}
    >
      <Grid item container xs={12} md={12} m='auto'>
        {props.child}
        <Grid item container justify='center' alignItems='center'>
          <Grid item>
            <AudioPlayer src = {pitch}/>
          </Grid>
          <Grid item style={{ color: "white", fontSize: 23, padding: 20 }}>
              OUR VISION: To be the brand people rely on for their unique abilities to shine.
          </Grid>
        </Grid>
        <Grid item container justify='center' alignItems='center' style = {{backgroundColor:"#0053FA"}}>
          <Grid item style={{ paddingTop: 10 }}>
            <TypingElement quotes={TypingQuotes.quotes}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

