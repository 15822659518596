import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import optus from '../assets/optus.png';
import { Link } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import logo from '../assets/logo-motif-navy-bg.png';
import wsa from "../assets/wsaShort.png";
import Styles from '../styles';

const useStyles = makeStyles((theme) => ({

    text: {
      color: '#FFFFFF', 
      font: "calibri",
      textTransform: "none",
      textAlign: "left",
      paddingBottom:0,
      fontSize:20
    }
  
  }));

function Scroll() {
    window.scrollTo(0, 0);
}

function BottomBar() {

    const colours = new Styles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
      Scroll();
      setAnchorEl(null);
    };


    const styles = useStyles();
    var width = window.innerWidth
    var direction = 'row';
    var xs = 6;
    var w = 300;
    var logoWidth = 120;
    var mobile = 1;
    if (width < 713) {
        direction = 'column';
        xs = 12;
        w = 200;
        logoWidth = "100%"
        mobile = 0;
    }

    return (
        <Grid container direction = "column" style = {{backgroundColor:colours.appBarColor,color:'white'}}>
            <Grid item container direction = {direction} justify="flex-start">
                <Grid item container xs = {xs} direction = {direction} justify = "space-between" alignItems = "flex-end" style = {{padding:10}}>
                    <Grid item container direction = "column" justify = "space-between" xs = {12} style = {{paddingLeft:20*mobile}}>
                        <Grid item style = {{paddingTop:20}}>
                            <Grid item container direction = {direction} justify = "space-around" alignItems = 'center'>
                                <Grid item>
                                    <img src = {logo} alt = "The aible logo" style = {{width:logoWidth}}/>
                                </Grid>
                                <Grid item container direction = "column" xs = {xs} alignItems = "flex-start">
                                    <Grid item>
                                        <Button className = {styles.text} onClick={handleClick}>
                                        ➢ FAQs
                                        </Button>
                                        <Menu
                                            className = {styles.menuButton}
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleClose} component={Link} to= "/candidateFAQ">JOB CANDIDATE FAQs</MenuItem>
                                            <MenuItem onClick={handleClose} component={Link} to= "/employerFAQ">EMPLOYER FAQs</MenuItem>
                                            <MenuItem onClick={handleClose} component={Link} to= "/espFAQ">ESP FAQs</MenuItem>
                                            <MenuItem onClick={handleClose} component={Link} to= "/rtoFAQ">RTO FAQs</MenuItem>
                                        </Menu>
                                    </Grid>
                                    <Grid item>
                                        <Button className = {styles.text} component={Link} to="/support" onClick={Scroll}>
                                        ➢ Contact Us
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button className = {styles.text} href = {process.env.PUBLIC_URL + '/__pdf/privacy_policy.pdf'} target = '_blank'> 
                                            ➢ Privacy Policy
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style = {{fontSize:15,padding:20}}>
                            We acknowledge the Traditional Custodians of the land on which we work and live, 
                            and recognise their continuing connection to the land, water and community. 
                            We pay respect to their Elders past, present and emerging.
                        </Grid>
                        <Grid item style = {{paddingTop:20}}>
                            Copyright © 2020 AiBLE. All Rights Reserved.
                        </Grid>
                    </Grid>
                    
                    
                </Grid>
            </Grid>
            
        </Grid>
    );
}

export default BottomBar;