import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../assets/logo-block-sqr-full-colour-trans-bg-test.png';
import { useAuthState, logout, useAuthDispatch } from '../Context';

import Styles from '../styles';

const colours = new Styles();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  text: {
    color: '#FFFFFF',
    fontSize: 20,
    paddingTop: 70,
    textTransform: 'none',
    paddingLeft: 10,
    paddingRight: 20,
    textDecoration: 'none',
  },
  bar: {
    flexGrow: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

export default function TopBarWeb(props) {
  const styles = useStyles();
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  //console.log(userDetails);

  function Scroll() {
    window.scrollTo(0, 0);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  return (
    <Grid
      container
      spacing={0}
      direction='row'
      justify='space-evenly'
      alignItems='flex-end'
    >
      <Grid item>
        <img src={logo} alt='' width={100} style={{ padding: 5 }} />
      </Grid>
      <Grid container item direction='row' lg={11} md={10}>
        <Grid item>
          <Button
            className={styles.text}
            component={Link}
            to='/partners'
            onClick={Scroll}
          >
            Our Partners
          </Button>
        </Grid>

        <Grid item>
          <Button className={styles.text} onClick={handleClick2}>
            Why AiBLE
          </Button>
          <Menu
            className={styles.menuButton}
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose2}
          >
            <MenuItem onClick={handleClose2} component={Link} to='/ourstory'>
              OUR STORY
            </MenuItem>
            <MenuItem onClick={handleClose2} component={Link} to='/meettheteam'>
              MEET THE TEAM
            </MenuItem>
            <MenuItem onClick={handleClose2} component={Link} to='/why'>
              AiBLE BENEFITS
            </MenuItem>
            <MenuItem onClick={handleClose2} component={Link} to='/brochure'>
              ONE PAGE BROCHURE
            </MenuItem>
            <MenuItem onClick={handleClose2} component={Link} to='/stories'>
              CUSTOMER STORIES
            </MenuItem>
            {/* 
            <MenuItem
              onClick={handleClose2}
              component={Link}
              to='/roadtolaunch'
            >
              ROAD TO LAUNCH
            </MenuItem>
            <MenuItem onClick={handleClose2} component={Link} to='/news'>
              NEWS
            </MenuItem>
            */}
          </Menu>
        </Grid>

        <Grid item className={styles.bar} />

        <Grid item>
          <Button
            className={styles.text}
            component={Link}
            to='/'
            onClick={Scroll}
          >
            Home
          </Button>
        </Grid>
        <Grid item>
            <Button
              className={styles.text}
              component={Link}
              to='/jobs'
              onClick={Scroll}
            >
              Jobs
            </Button>
          </Grid>

        <Grid item>
          <Button
            className={styles.text}
            component={Link}
            to='/search'
            onClick={Scroll}
          >
            Career Services
          </Button>
        </Grid>

        <Grid item>
          <Button className={styles.text} onClick={handleClick}>
            Learn
          </Button>
          <Menu
            className={styles.menuButton}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} component={Link} to='/trainhome'>
              AiBLE TRAINING
            </MenuItem>
          </Menu>
        </Grid>
        
        <Grid item>
          <Button
            className={styles.text}
            component={Link}
            to='/espportal'
            onClick={Scroll}
          >
            Support Providers
          </Button>
        </Grid>
        

        <Grid item>
          <Button
            className={styles.text}
            component={Link}
            to='/corpportal'
            onClick={Scroll}
          >
            Corporate Customers
          </Button>
        </Grid>
        
        <Grid item>
            <Link
              className={styles.text}
              style={{ padding: 0 }}
              to='/app'
            >
              <Button className={styles.text} style={{ color: colours.green }}>
                Login
              </Button>
            </Link>
        </Grid>
        
      </Grid>
    </Grid>
  );
}
