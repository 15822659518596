import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import Grid from '@material-ui/core/Grid';
import { Button, RadioGroup, Radio, FormControlLabel, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Styles from '../styles';

const colours = new Styles();

const globalKey = uuidv4();
const useStyles = makeStyles((theme) => ({
    button: {
        border: "solid #CC99FF 3px",
        backgroundColor:'white',
        paddingLeft:25,
        paddingRight:25,
        '&:hover': {
            backgroundColor:'grey'
        }
    },
    center: {
        backgroundColor:"white",
        maxWidth:1000,
        margin:'auto',
        textAlign:'center',
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    icon: {
        borderRadius:'50%',
        width: 24,
        height: 24
    },
    iconDisabled: {
        color: 'primary'
    },
    checkedIcon: {
        width: 16,
        height: 16,
    },
    radio: {
        border: 'solid red 1px',
    },
    radioDisabled: {
        border: 'solid green 1px',
    },
    end: {
        fontSize: 20,
        color: 'white'
    }
}));

export default function TrainingQuiz(props) {

    const styles = useStyles();

    const colour = props.colour;

    const [slideNum, setSlideNum] = React.useState(0);
    const [sendRequest, setSendRequest] = React.useState(true);
    const [slides,setSlides] = React.useState([]);
    const [disableBack,setDisableBack] = React.useState(true);
    const [disableNext,setDisableNext] = React.useState(false);
    
    const course = props.course;

    const back = () => {
        if (slideNum > 0) {
            setSlideNum(slideNum-1);
        }
        if (slideNum === 0) {
            setDisableBack(true);
        }
        setDisableNext(false)
    }

    const next = () => {
        if (slideNum < slides.length - 1) {
            setSlideNum(slideNum+1);
        } else {
            setDisableNext(true);
            
        }
        setDisableBack(false);

    }

    const disableNext_ = (value) => {
        setDisableNext(value);
    }


    const getQuizModule = () => {

        axios.get("https://quarkus.aible.app/api/public/quizs/courses/"+course)
            .then((response) => {
                response.data.map( (item) => {
                    
                    if (item.moduleUrl !== "noSlide") {
                        item.moduleUrl.split(",").forEach((item2) => {
                            setSlides(slides => [...slides, <iframe title = "slide" frameBorder = {0} style = {{height:620,width:"100%"}} src = {item2.replace("/edit#slide=id.p","/preview?rm=minimal&slide=id.p")}/>])
                        });
                    }
                    if (item.code !== "QUE_DUMMY") {
                        setSlides(slides => [...slides, <Question disable = {false} key = {slides.length} question = {item} disableNext = {disableNext_} course = {course}/> ])
                    }
                    }   
                )
                setSlides(slides => [...slides, <EndingSlide course = {course}/>])
                if (slides !== []) {
                    setSendRequest(false);
                }
                setDisableNext(false)
            }, (error) => {
                console.log(error);
            });
    }



    React.useEffect(() => {

        if (sendRequest) {
            getQuizModule();
            
        }
    },[sendRequest]);



    var bottomButtons = <div className = {styles.center} style = {{backgroundColor:colours.appBarColor}}>
        <Grid container direction = 'row'>
                <Grid item style = {{padding:20}}>
                    <Button disabled = {disableBack} className = {styles.button} onClick = {back}>
                        Back
                    </Button>
                </Grid>
                <Grid item style = {{flexGrow:1}}/>
                <Grid item style = {{padding:20}}>
                    <Button disabled = {disableNext} className = {styles.button} onClick = {next}>
                        Next
                    </Button>
                </Grid>
            </Grid>
    </div>
            


    return (
        <div >
            <TopBar
                child = {
                    <div style = {{padding:0,paddingBottom:50,minHeight:800}}>
                        <PageHeader colour = {colour} title = {props.title}/>
                        <br/>
                        {bottomButtons}
                        <div className = {styles.center} style = {{minHeight:600}}>
                            {slides[slideNum]}
                            
                        </div>
                    </div>
                }
            />
        </div>
    );
}

class Question extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            question:props.question,
            disable:props.disable,
            name:props.question.name,
            number:props.question.questionNumber,
            disableNext:props.disableNext,
            courseCode:props.course
        }
    }

    change = (event) => {
        this.state.disableNext(false);
        var value = event.target.value.replace("@","");

        while (value.includes(" ")) {
            value = value.replace(" ","%20")
        }
        var api = "https://quarkus.aible.app/api/public/quizs/result/"+globalKey+"/"+this.state.courseCode+"/"+this.state.number+"/"+value;
        axios.post(api).then((response) => {
        })
    }

    componentDidMount() {
        this.state.disableNext(true);
    }


    render() {
        return (
            <div style = {{backgroundColor:colours.green,padding:10,width:'50%',height:580}}>
                <div style = {{width:'100%',textAlign:'left',backgroundColor:"white",height:"100%"}}>
                    <div style = {{textAlign:'left',paddingTop:20,paddingBottom:20,font:'Arial',width:'100%',backgroundColor:'#D8D8D8',color:'#7F7F7F'}}>
                        <div style = {{padding:5,paddingLeft:20}}>
                            <span style = {{paddingRight:10}}>Q{this.state.number}.</span><span>{this.state.name}</span>
                        </div>
                    </div>
                    <RadioGroup>
                    {
                        this.state.question.selections.split(";").map( 
                            (item) => <div>
                                <FormControlLabel
                                    disabled = {this.state.disable}
                                    style = {{backgroundColor:item.colour,paddingLeft:30,paddingRight:5,color:'black'}} 
                                    value = {item} 
                                    control = {
                                        <Radio 
                                            onChange = {this.change}
                                            color = {"primary"}
                                        />
                                    }
                                    label = {item.replace("@","")}/>
                                <Divider/>
                                </div>
                        )
                    }
                    </RadioGroup>
                </div>
            </div>
        );
    }
}


class EndingSlide extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: props.key,
            courseCode: props.course,
            result:0
        }
    }

    componentDidMount() {
        axios.get("https://quarkus.aible.app/api/public/quizs/results/"+this.state.courseCode+"/"+globalKey+"/score").then((response) => {

            var res = Math.round((response.data.score/response.data.total)*100);

            this.setState({result:res});
        })
    }


    render() {
        var result = this.state.result;
        var topLine = "";
        var button = <div/>;

        if (this.state.result === 100) {
            topLine = "Congratulations, you are now Disability Aware";
            result = "Completed: 100";
            button = <div/>
        } else {
            topLine = "Almost There";
            button = <Button style = {{border: "solid #CC99FF 3px",backgroundColor:'white',paddingLeft:25,paddingRight:25,'&:hover': {backgroundColor:'grey'}}}component = {Link} to = "/trainhome">
                        PLEASE TRY AGAIN
                    </Button>
        }

        return (
            <div style = {{backgroundColor:'#363636',width:'100%',height:'100%',minHeight:600,color:'white'}}>
                <div style = {{padding:30}}>
                    <Grid container justify = "center" alignItems = "center">
                        <Grid item>
                            <span style = {{fontSize: 25,color: 'white'}}>{topLine}</span>
                            <br/><br/>
                            <span style = {{fontSize: 25,color: 'white'}}>{result}%</span>
                            <br/><br/>
                            {button}
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

