import { Divider, Grid, List, ListItem } from "@material-ui/core";
import React from "react";
import bridge from "../assets/video/bridgeworksPromo.mp4";
import PageHeader from "../elements/PageHeader";
import TopBar from "../elements/TopBar";
import wsa from "../assets/wsa.png";
import socialTraders from "../assets/socialTraders_logo.jpg";
import optus from '../assets/optus.png';
import startup from '../assets/startup.png';
import smallbusiness from '../assets/smallbusiness.png';

export default function News(props) {
  const elements = [
    <NewsItem
      type="image"
      src={wsa}
      width = {200}
      date="3 Nov '20"
      desc="AiBLE has been selected as best and most innovative digital solution from our country and we are now competing with nominations from almost 80 countries - what an exciting recognition, especially in this challenging times."
    />,
    <NewsItem
      type="image"
      width={100}
      src={socialTraders}
      date="25 Sept '20"
      desc="We are thrilled to have become accredited as a Social Enterprise through Social Traders. A Social Enterprise is a business that trades intentionally to tackle social problems, improves communities and provides people access to employment and training. Being listed as a Social Enterprise means if companies use AiBLE’s services they will comply with Government and corporate social procurement criteria in the tender process. Increasing social procurement spend helps to create positive community outcomes."
    />,
    <NewsItem
      type="video"
      width={400}
      src={bridge}
      date="24 Sept '20"
      desc="It’s a privilege to support our wonderful partner, Groomed to Go by supporting their funding initiative with Bridgeworks. We booked one of Wayne’s training sessions to help our AiBLE team understand each other better to improve our communication and leadership skills. I highly recommend Bridgeworks so please support Groomed to Go – a wonderful service that offers a dressing and support service for people who need to rebuild their self-confidence and self-esteem as they seek work. "
    />,
    <NewsItem
      type="text"
      date="14 Aug '20"
      desc="We signed a deed with the Department of Education, Skills and Employment to begin the process of becoming accredited as a Third Party Employment Systems Provider.  Once accredited, this will mean the Government will allow their Employment Service Providers to use the AiBLE platform to support the delivery of their programs."
    />,
    <NewsItem
      type="image"
      src={optus}
      width={250}
      date="4 Mar '20"
      desc="We were announced as one of five finalists in the Optus Future Makers program.  This is a capacity building and accelerator program for social start-ups that use technology to address social challenges for disadvantaged and vulnerable people. Since March, we have participated in a series of workshops and mentoring sessions by Optus top talent and business leaders and we’ve had the opportunity to network with other finalists and social innovation leaders.  The aim of the program is to advance AiBLE to increase our social impact and technology expertise."
    />,
    <NewsItem
      type="image"
      src={smallbusiness}
      width={100}
      date="26 Nov '19"
      desc="We were a finalist in the 2020 Australian Small Business Awards."
    />,
    <NewsItem
      type="image"
      src={startup}
      width={120}
      date="19 Aug '19"
      desc="We were a finalist in the 2019 My Business Awards for ‘Start-up Business of the Year – Less than 10 Employees.’ The winners were announced at a gala dinner at The Star in Sydney on the 15th November.  Although we didn’t win, we were honoured to be nominated and had a fantastic time networking with the other finalists at the dinner."
    />,
    <NewsItem
      type="text"
      date="21 Jul '19"
      desc="The initial version of AiBLE was released on the App Store. This was a milestone achievement and a culmination of three years of research and development, blood, sweat and tears. The purpose of this early release was to work with Employment Support Providers to onboard as many of their clients as part of  the co-design of the full version of AiBLE."
    />,
  ];

  return (
    <div>
      <TopBar child={<PageHeader colour={props.colour} title="NEWS" />} />
      <br />
      <List>{elements.map((item) => item)}</List>
    </div>
  );
}

function NewsItem(props) {
  var content = <div />;

  var width = window.innerWidth;
  var mobile = 1;
  var contentWidth = 400;
  var descWidth = 500;
  var dateWidth = 110;
  var totalWidth = 1010;

  if (width < 713) {
    mobile = 0;
    contentWidth = width * 0.9;
  }

  switch (props.type) {
    case "video":
      contentWidth = props.width;
      descWidth = totalWidth - dateWidth - contentWidth;
      content = (
        <video
          controls
          src={props.src}
          alt={props.desc}
          style={{ width: contentWidth }}
        />
      );
      break;
    case "image":
      contentWidth = props.width;
      descWidth = totalWidth - dateWidth - contentWidth;
      content = (
        <img src={props.src} alt={props.desc} style={{ width: contentWidth }} />
      );
      break;
    case "text":
      descWidth = descWidth + contentWidth;
      contentWidth = 0;
      break;
    default:
      break;
  }

  return (
    <div>
      <ListItem>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item style={{ textAlign: "right", width: 110 }}>
            {props.date}
          </Grid>
          <Grid item style={{ width: descWidth }}>
            {props.desc}
          </Grid>
          <Grid item>{content}</Grid>
          <Grid item>
            <Divider style={{ width: 110 + contentWidth + descWidth }} />
          </Grid>
        </Grid>
      </ListItem>
    </div>
  );
}
