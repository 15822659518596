import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import diversity from '../assets/aible-diversity.svg';
import inclusion from '../assets/aible-inclusion.svg';
import privacy from '../assets/aible-privacy.svg';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 80,
    },
    title: {
        fontSize: 25,
        fontWeight: "bold"
    },
    body: {
        fontSize: 20,
    },
    column: {
        paddingBottom:20,
    },
}));

function Values() {

    const xs = 10;
    const md = 3;
    const styles = useStyles();

    return (
        <Grid container direction = "row" alignItems = 'center' justify = 'space-evenly' style={{color:"white"}}>
            <Grid item container direction = "column" className = {styles.column} xs = {xs} md = {md}>
                <Grid item>
                    <img src = {diversity} alt = "" className = {styles.icon}/>
                </Grid>
                <Grid item className = {styles.title}>
                    DIVERSITY
                </Grid>
                <Grid item className = {styles.body}>
                    We understand the benefits of
                    diversity within the workplace and
                    provide the ability to search on
                    specific attributes to improve diversity.
                </Grid>
            </Grid>

            <Grid item container direction = "column" className = {styles.column} xs = {xs} md = {md}>
                <Grid item>
                    <img src = {inclusion} alt = "" className = {styles.icon}/>
                </Grid>
                <Grid item className = {styles.title}>
                    INCLUSION
                </Grid>
                <Grid item className = {styles.body}>
                    We are passionate about creating
                    equal access to opportunities for
                    those who might otherwise be
                    excluded or marginalised.
                </Grid>
            </Grid>

            <Grid item container direction = "column" className = {styles.column} xs = {xs} md = {md}>
                <Grid item>
                    <img src = {privacy} alt = "" className = {styles.icon}/>
                </Grid>
                <Grid item className = {styles.title}>
                    PRIVACY
                </Grid>
                <Grid item className = {styles.body}>
                    All candidates are anonymous within
                    AiBLE to ensure their privacy is
                    protected. This also provides a level
                    of comfort to disclose abilities.
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Values;