import React from 'react';
import TopBar from '../elements/TopBar';
import Values from '../elements/Values';
import HeaderHome from '../elements/headerHomes/HeaderHomeDefault';
import SeeMore from '../elements/SeeMore';
import Quotes from '../elements/Quotes';
import Description from '../elements/Description';

function Home(props) {

    return (
        <div>
            <TopBar child = {props.child}/>
            <br/>
            <br/>
            <Values/>
            <br/>
            <SeeMore/>
            <br/>
            <Quotes/>
            <Description/>
        </div>
    );

}

export default Home;