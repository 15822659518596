import React, {useState} from 'react';
import CheckoutForm from "./CheckoutForm.js";
import ProductSelect from './ProductSelect.js';


export default function PageSelector(props) {

    const handleProduct = (product) => {
        console.log(product);
        setPage(<CheckoutForm product = {product} authToken = {props.authToken} countryCode = {props.countryCode}/>);
    }

    const [page, setPage] = useState(<ProductSelect select = {handleProduct} authToken = {props.authToken} countryCode = {props.countryCode}/>);

    


    return (
        <div>
            {page}
        </div>
    );

}