import React, {useState, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { Grid, Button, Box, Dialog, DialogTitle, TextField } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({

    payment: {
        backgroundColor: '#7290C9',
        borderRadius: "20px",
        width:"50%",
        color: "#FFFFFF",
        marginLeft:"25%",
        marginRight:"25%",

        '&:hover': {
            backgroundColor: "#1F4F9D"
        }
    }

}));



export default function CheckoutForm(props) {

    const postMessage = (message) => {
        if (window.parent !== undefined) {
            window.parent.postMessage(message,"*");
        }
        
        if (window.Print !== undefined) {
            window.Print.postMessage(message);
        }
    }

    const styles = useStyles();

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const stripe = useStripe();
    const elements = useElements();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");
    const [coupon, setCoupon] = useState("");
    const [helpText, setHelpText] = useState("");
    const [canPress, setCanPress] = useState(true);

    const couponRef= useRef("");

    

    const authToken = props.authToken;

    const handleCouponChange = (event) => {
        setHelpText("");
        setCoupon(event.target.value.toUpperCase());
        couponRef.current = event.target.value.toUpperCase();
    };

    const handleSubmit = async (event) => {
        console.log("*************Coupon is: " + couponRef.current);
        
        var currentCoupon = couponRef.current;

        event.preventDefault();
        var proceed = false;
        if (!stripe || !elements || !canPress) {
            return;
        }
        
        setCanPress(false);
        setButton(<div  style={{textAlign: 'center' }}>Your payment is being processed, you will be returned to the app when it is complete</div>)
        
        if (currentCoupon.trim() !== "") {
            
            var couponUrl = decodeURI(window.location.href.split("&baseurl=")[1].split("&country_code=")[0])+"/stripe/validate/"+currentCoupon.trim();
            
            await axios.get(
                couponUrl,
                {
                    headers: {
                        "Authorization": "Bearer "+authToken,
                        "Content-Type":"application/json",
                        "Accept":"application/json"
                    }
                }
            ).then(
                async (response)  =>  {
                    if (response !== undefined) {
                        console.log(response.statusText+ ":"+response.status.toString())
                        if (response.status === 200) {
                            console.log(JSON.stringify(response.data));
                            if (response.data.valid === "VALID") {
                                
                                proceed = true;
                                await setHelpText(response.data.message);
                            } else {
                                await setError(response.data.message);
                                await setOpen(true);
                            }
                        }
                    } else {
                        postMessage("ERROR:Something went wrong");
                    }
                },
                (error) => {
                    console.log(error);
                    postMessage("ERROR:Something went wrong");
                }
            );
        } else {
            proceed = true;
        }

        if (proceed) {

            const token = await stripe.createToken(elements.getElement(CardNumberElement)).then(function(result) {
                if (result.token) {

                    var url = decodeURI(window.location.href.split("&baseurl=")[1].split("&country_code=")[0])+"/stripe/charge";
                    var couponCode = currentCoupon.trim();

                    if (couponCode === "") {
                        couponCode = null;
                    }
                    console.log("Attempting charge");
                    axios.post(
                        url,
                        {
                            priceId:props.product.priceId,
                            source:result.token.id,
                            coupon:couponCode
                        },
                        {
                            headers: {
                                "Authorization": "Bearer "+authToken,
                                "Content-Type":"application/json",
                                "Accept":"application/json"
                            }
                        }
                    ).then(
                        (response) => {
                            if (response !== undefined) {
                                if (response.status === 200) {
                                    postMessage("DONE");
                                }
                            } else {
                                postMessage("ERROR:Something went wrong");
                            }
                        
                        },
                        (error) => {
                            console.log(error);
                            postMessage("ERROR:Something went wrong");
                        }
                    );
                } else {
                    setButton( <Button className={styles.payment} /*disabled={!stripe}*/ onClick={handleSubmit}>
                    Pay
                </Button>)
                setCanPress(true);
                    setError(result.error.message);
                    setOpen(true);
                }
            });

        }

        setButton(
            <div>You will be returned to the app shortly. If you are not, close this page and return to the app</div>
        )

    }
    const handleClose = () => {
        setOpen(false);
    }

    const [button, setButton] = useState(
        <Button className={styles.payment} /*disabled={!stripe}*/ onClick={handleSubmit}>
            Pay
        </Button>
    );


    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Error: {error}</DialogTitle>
            </Dialog>
            <Grid container direction = "column">
                <Grid item style = {{textAlign:"center"}}>
                    You are authorising the purchase of:<br/> {props.product.name}<br/>
                    For ${numberWithCommas(props.product.totalPrice/100)} {props.product.currency.toUpperCase()} ({props.product.description})
                </Grid>
                <Grid item>
                    <Card/>
                </Grid>
                <Grid item style = {{textAlign:"center"}}>
                    <div style={{padding:10}}>
                        <Box boxShadow={3} style={{padding:10,borderRadius:"10px"}}>
                            <TextField label = "Redeem Code" variant="outlined" value={coupon} onChange={handleCouponChange} helperText={helpText}/>
                        </Box>
                    </div>
                </Grid> 
                <br/>
                {button}
            </Grid>
        </div>
    );

}


function Card() {

    const border = "solid #AFAFAF 1px";
    const borderRadius = "5px";
    const padding = 5;

    const style = {
        border:border,
        borderRadius:borderRadius,
        padding:padding,
    }
    const halfStyle = {
        border:border,
        borderRadius:borderRadius,
        padding:padding,
        width:"45%"
    }

    const spacer =  <div style={{padding:3}}/>;

    return (
        <div style={{padding:10}}>
            <Box boxShadow={3} style={{padding:10,borderRadius:"10px"}}>
                <div style={{color:"#AFAFAF"}}>
                    CARD DETAILS
                </div>
                {spacer}
                <div style = {style}>
                    <CardNumberElement options={{showIcon:true}}/>
                </div>
                {spacer}
                <Grid container direction = "row" justify="space-between">
                    <div style = {halfStyle}>
                        <CardCvcElement/>
                    </div>
                    <div style = {halfStyle}>
                        <CardExpiryElement/>
                    </div>
                </Grid>
                
            </Box>
        </div>
    );
}