import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import logo from '../assets/logo-block-sqr-full-colour-trans-bg-test.png';

const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1,
  },
  line: {
    borderBottom: "4px solid white",
    borderTop: "4px solid white",
  },
  text: {
    color: '#FFFFFF',
    fontSize: 20,
    paddingTop: 20,
    font: "calibri",
    textTransform: "none",
    paddingLeft: 10,
    paddingRight: 10,
    textDecoration: 'none',
  },
  bar: {
    flexGrow: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },

}));

export default function TopBarWeb(props) {

  const styles = useStyles();

  function Scroll() {
    window.scrollTo(0, 0);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  return (
    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
      <img src={logo} alt="" width="100%" />
      <Accordion className={styles.line} style={{ backgroundColor: 'black' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} />
        <AccordionDetails>
          <Grid container item direction="column">
            <Grid item>
              <Button className={styles.text} component={Link} to="/partners" onClick={Scroll}>
                Our Partners
            </Button>
            </Grid>

            <Grid item>
              <Button className={styles.text} onClick={handleClick2}>
                Why AiBLE
                </Button>
              <Menu
                className={styles.menuButton}
                anchorEl={anchorEl2}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={handleClose2}
              >
                <MenuItem onClick={handleClose2} component={Link} to="/meettheteam">MEET THE TEAM</MenuItem>
                <MenuItem onClick={handleClose2} component={Link} to="/why">AiBLE BENEFITS</MenuItem>
                <MenuItem onClick={handleClose2} component={Link} to="/stories">CUSTOMER STORIES</MenuItem>
                {/*  <MenuItem onClick={handleClose2} component={Link} to="/roadtolaunch">ROAD TO LAUNCH</MenuItem>
                <MenuItem onClick={handleClose2} component={Link} to="/news">NEWS</MenuItem > */}

              </Menu>
            </Grid>

            <Grid item>
              <Button className={styles.text} component={Link} to="/" onClick={Scroll}>
                Home
            </Button>
            </Grid>
            <Grid item>
              <Button className={styles.text} component={Link} to="/jobs" onClick={Scroll}>
                Jobs
            </Button>
            </Grid>
            <Grid item>
              <Button className={styles.text} component={Link} to="/search" onClick={Scroll}>
                Career Services
            </Button>
            </Grid>


            <Grid item>
              <Button className={styles.text} onClick={handleClick}>
                Learn
                </Button>
              <Menu
                className={styles.menuButton}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/*<MenuItem onClick={handleClose} component={Link} to= "/trainhome">AiBLE TRAINING</MenuItem>*/}
                {/* <MenuItem onClick={handleClose} component={Link} to="/news">NEWS</MenuItem> */}
              </Menu>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
