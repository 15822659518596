import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Box } from '@material-ui/core';
import axios from 'axios';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Styles from '../styles';

const colours = new Styles();
function SearchElement() {
  
  const searches = [
    { text: 'Qual Check', index: 0 },
    { text: 'Job Check', index: 1 },
    { text: 'Skills Check', index: 2 },
  ];
  const searchLabels = [
    'Enter Job Title',
    'Enter Qualification',
    'Enter Job Title',
  ];
  const [search, setSearch] = React.useState(0);
  const [searchLabel, setSearchLabel] = React.useState(searchLabels[0]);
  const [results, setResults] = React.useState([]);
  var searchVal = '';
  const api = 'https://quarkus.aible.app/api/public/';
  const descriptions = [
    {
      a: 'Enter a job title and ',
      b: 'select it from the list',
      c: ' to view the relevant education pathways',
    },
    {
      a: 'Enter a qualification and ',
      b: 'select it from the list',
      c: ' to view the relevant jobs. Click on the arrow to read a job summary',
    },
    {
      a: 'Enter a job title and ',
      b: 'select it from the list',
      c:
        ' to see the jobs based on a similar skills. Click on the arrow to read a job summary',
    },
  ];
  const bigdescs = [
    'QUAL CHECK helps you understand what qualifications will help you get the job you want',
    'JOB CHECK helps you understand the types of jobs your qualification could lead to',
    'SKILLS CHECK helps you broaden your job search by identifying other jobs that you could also be matched with, as  they are jobs based on similar skills',
  ];
  const [description, setDescription] = React.useState(descriptions[0]);
  const [bigdesc, setBigdesc] = React.useState(bigdescs[0]);
  const [entries, setEntries] = React.useState([]);

  const handleChange = (event, newValue) => {
    setSearch(newValue);
    setSearchLabel(searchLabels[newValue]);
    setDescription(descriptions[newValue]);
    setBigdesc(bigdescs[newValue]);
    setEntries([]);
    setResults([]);
    searchVal = '';
  };

  const searchOnChange = (event) => {
    if (event.target.textContent === '') {
      searchVal = event.target.value;
      requestAuto();
      setResults([]);
    } else {
      searchVal = event.target.textContent;
      requestSearch(searchVal);
    }
  };

  const requestAuto = () => {
    var value = searchVal;
    if (value === undefined) value = '';

    if (value.length >= 3) {
      var apipath = '';
      var params = {};
      if (search === 0 || search === 2) {
        apipath = 'roles';
        params = {
          role: value,
        };
      } else if (search === 1) {
        apipath = 'qualifications';
        params = {
          qualification: value,
        };
      }
      axios
        .get(api + apipath, {
          params: params,
        })
        .then(
          (response) => {
            setEntries(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      setEntries([]);
    }
  };

  const requestSearch = (select) => {
    var apipath = '';
    var params = {};
    if (search === 0) {
      apipath = 'qualcheck';
      params = {
        role: select,
      };
    } else if (search === 1) {
      apipath = 'jobcheck';
      params = {
        qual: select,
      };
    } else {
      apipath = 'skillscheck';
      params = {
        role: select,
      };
    }
    axios
      .get(api + apipath, {
        params: params,
      })
      .then(
        (response) => {
          setResults(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  var width = window.innerWidth;
  var direction = 'row';
  var mobile = 1;
  var autoWidth = 500;
  var bigFont = 25;
  var selectFont = 15;
  if (width < 713) {
    direction = 'column';
    mobile = 0;
    autoWidth = window.innerWidth;
    bigFont = 20;
    selectFont = 12;
  }

  return (
    <div>
      <Box boxShadow={3} style={{ backgroundColor: colours.appBarColor, padding: 10 }}>
        <Tabs
          value={search}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          centered
        >
          {searches.map((item) => (
            <Tab
              style={{
                color: 'white',
                backgroundColor: colours.appBarColor,
                fontSize: selectFont,
              }}
              value={item.value}
              label={item.text}
              key={item.vaule}
            ></Tab>
          ))}
        </Tabs>
      </Box>
      <div
        style={{
          minHeight: 800,
          width: '40%',
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: 30, paddingTop: 0 }}></div>
        <div style={{ paddingBottom: 50 }}>
          <p style={{ fontSize: bigFont, maxWidth: 850 }}>{bigdesc}</p>
          <br />
          <div style={{ fontSize: 17 }}>
            <span>{description.a}</span>
            <span style={{ fontWeight: 'bold' }}>{description.b}</span>
            <span>{description.c}</span>
          </div>
        </div>

        <div style={{ padding: 50 * mobile, paddingTop: 0 }}>
          <Grid
            container
            direction='row'
            style={{
              backgroundColor: 'white',
              padding: 6 * mobile,
              paddingTop: 6,
              paddingBottom: 6,
            }}
          >
            <Autocomplete
              key={search}
              options={entries}
              getOptionLabel={(option) => option}
              style={{ width: autoWidth, backgroundColor: 'white' }}
              filterOptions={(options, state) => options}
              onInputChange={searchOnChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color='primary'
                  label={searchLabel}
                  variant='outlined'
                />
              )}
            />
          </Grid>
        </div>
        <div>
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
          >
            {results.map((item) => (
              <Result disabled={search === 0} item={item} />
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}

function Result(props) {
  const api = 'https://quarkus.aible.app/api/public/';
  var width = window.innerWidth;
  var style = {
    textAlign: 'left',
    minWidth: 500,
    maxWidth: 500,
    paddingBottom: 15,
    padding: 10,
  };
  if (width < 500)
    style = { textAlign: 'left', width: width, paddingBottom: 15, padding: 10 };

  const [desc, setDesc] = React.useState('loading...');
  const [source, setSource] = React.useState('');

  const openAccordion = (event, expanded) => {
    if (expanded) {
      axios.get(api + 'rolenames/' + props.item.replace('/', '%2F')).then(
        (response) => {
          setDesc(response.data.description);
          setSource('Source: ' + response.data.source);
        },
        (error) => {
          console.log(error.request);
          setDesc(
            'We had some trouble reaching the server, please try again later'
          );
          setSource('Error Code: ' + error.request.status);
        }
      );
    }
  };
  var icon = <ExpandMoreIcon />;

  var details = (
    <AccordionDetails>
      <div style={{ paddingLeft: 10 }}>
        <span>{desc}</span>
        <br />
        <br />
        <div
          style={{
            flexGrow: 1,
            textAlign: 'right',
            fontSize: 10,
            color: 'grey',
          }}
        >
          {source}
        </div>
      </div>
    </AccordionDetails>
  );

  var accordion = (
    <Accordion
      onChange={openAccordion}
      style={{
        background: 'white',
        border: 'solid lightgrey 1px',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary expandIcon={icon} style={{ color: 'black' }}>
        <span style={{ paddingLeft: 10, fontWeight: 'bold' }}>
          {props.item.trim()}
        </span>
      </AccordionSummary>
      {details}
    </Accordion>
  );

  if (props.disabled) {
    icon = <div />;
    details = <span />;
    accordion = (
      <Accordion
        expanded={false}
        onChange={openAccordion}
        style={{
          background: 'white',
          border: 'solid lightgrey 1px',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary expandIcon={icon} style={{ color: 'black' }}>
          <span style={{ paddingLeft: 10, fontWeight: 'bold' }}>
            {props.item.trim()}
          </span>
        </AccordionSummary>
        {details}
      </Accordion>
    );
  }

  return <div style={style}>{accordion}</div>;
}

export default SearchElement;
