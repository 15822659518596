import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import Slideshow from '../elements/Slideshow';
import { Grid } from '@material-ui/core';

const quotes = [
    {
        q: "“I can do this too!”",
        a: "a man in his 40s with Down Syndrome, legally blind and illiterate, when he completed his AiBLE Candidate Profile with support from his ESP"
    },
    {
        q: "“AiBLE will help employers think about Job Sharing to include our clients who can’t work a full job”",
        a: "an ESP employment consultant who supports people with significant disability"
    },
    {
        q: "“My students spoke about how the Profile process gave them visibility and an improved sense of self-worth”",
        a: "a teacher of students hard of hearing"
    },
    {
        q: "“We will include the AiBLE Candidate Profile process in our curriculum for 2020”",
        a: "a teacher from a Special School in WA"
    },
    {
        q: "“I can see how AiBLE will be able to help our students find suitable jobs”",
        a: "a school principle of multiple Special Schools in NSW"
    },
    {
        q: "“AiBLE gives us the reporting we need for our client’s job searching activities”",
        a: "an ESP regional manager in VIC"
    },
    {
        q: "“AiBLE allows us to do targeted searching so we can include people like ex-offenders”",
        a: "an employer who focuses on helping those who are incarcerated"
    },
    {
        q: "“We are going to promote AiBLE for our branch managers to use to improve our recruiting of people over 50”",
        a: "a Diversity and Inclusion Manager of a corporate organisation"
    },
    {
        q: "“Our IT Manager wants to source people with Autism as he understands their special skills and how they add value to roles for areas such as cyber security”",
        a: "an HR Manager from a large financial institution"
    },
    {
        q: "“no other job app out there can do what AiBLE can”",
        a: "a Senior HR Manager from an enterprise IT organisation"
    }
]


function Stories() {
    var width = window.innerWidth;
    var slideWidth = 900;

    var iframe = <iframe title="pdf" style={{ width: '100%', height: 700 }} src={process.env.PUBLIC_URL + '/__pdf/caseStudy.pdf'} />
    var mobile = 1;
    if (width < 713) {
        iframe = <div />
        mobile = 0.1;
    }

    if (width < 713) {
        slideWidth = window.innerWidth;
    }

    return (
        <div>
            <TopBar
                child={<PageHeader colour='#6699FF' title="CUSTOMER STORIES" />} />
            <div style={{ minHeight: 400, textAlign: 'center', paddingTop: 20, color: "white"}}>
                <Grid container justify="center">
                    <Grid item>
                        <div style={{ maxWidth: slideWidth }}>
                            <Slideshow type = "story" items={quotes} />
                        </div>
                    </Grid>
                </Grid>
                <div style={{ minHeight: 800 * mobile, textAlign: 'center', padding: 80, paddingTop: 20 }}>
                    <a class='link' rel="noopener noreferrer" href={process.env.PUBLIC_URL + '/__pdf/caseStudy.pdf'} target="_blank" alt="open in new tab">Open PDF in new tab</a>
                    {iframe}
                </div>
            </div>
        </div>
    );
}

export default Stories;