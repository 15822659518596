export default class Styles {
    lightgreen = "#ACD8B8";
    green = "#68B770";
    blue = "#1F4F9D";
    grey = "#BFBFBF";
    yellow = "#FFFF66";
    purple = '#7A1758';
    lightblue = '#7290C9'
    charcoalGrey = '#2F2F35';
    appBarColor = "#0C1822";
}