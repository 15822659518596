import { MobileFriendly } from '@material-ui/icons';
import axios from 'axios';
import qs from 'qs';

const ROOT_URL = process.env.REACT_APP_KLOKE_URL;
console.log(ROOT_URL);

export async function loginUser(dispatch, { email, password }) {
  //console.log(email);
  //console.log(password);
  var data = qs.stringify({
    username: email,
    password: password,
    grant_type: process.env.REACT_APP_GRANT_TYPE,
    client_id: process.env.REACT_APP_CLIENT_ID,
    //client_secret: process.env.REACT_APP_CLIENT_SECRET,
  });

  //console.log(data);

  var config = {
    method: 'post',
    url: ROOT_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    },
    data: data,
  };

  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    let responce = await axios(config);
    //console.log(`axios response: ${JSON.stringify(responce)}`);
    let data = responce.data;
    //console.log(`axios data: ${JSON.stringify(data)}`);

    if (data.access_token) {
      data = { ...data, email };
      dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      localStorage.setItem('currentUser', JSON.stringify(data));
      localStorage.setItem('token', data.access_token);
      return data;
    }

    dispatch({ type: 'LOGIN_ERROR', error: 'Token Error' });
    return;
  } catch (error) {
    console.log(`axios error: ${error}`);
    dispatch({ type: 'LOGIN_ERROR', error: error.message });
  }
}

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
}
