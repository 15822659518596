import React from 'react';
import Grid from '@material-ui/core/Grid';

function Description() {
    return (
        <Grid container direction="column" justify="flex-start">
            <Grid item container direction="row" justify="flex-start" alignItems="stretch">

            </Grid>
            <Grid item container direction="row" justify="flex-start" alignItems="stretch">
                <Grid item style={{ padding: 20, backgroundColor: "#BFBFBF", flexGrow: 1, fontSize: 19, display: 'flex', justifyContent: 'center' }}>
                    <div style={{ alignSelf: 'center', textAlign: 'left' }}>
                        Heimdall Services Pty Ltd is the Australian company behind the AiBLE platform and is owned <br />
                        by a family that is passionate about making a positive difference to people's lives. <br />
                        <br />
                        AiBLE is not only made in Melbourne, we are proud to say “AiBLE is built by student app <br /> developers learning and solving real business innovation.”
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Description;