import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import stlogo from '../../assets/socialTraders_logo.jpg';
import Ellipse from '../../assets/backgroundEllipse.svg';
import AppStoreButton from '../AppStoreButton';
import Styles from '../../styles';

const useStyles = makeStyles((theme) => ({
  typing: {
    padding: 10,
    paddingLeft: 30,
  },
  logoNoSlogan: {
    width: 180,
  },
  jobactive: {
    padding: 0,
  },
  flexGrow: {
    flexGrow: 1,
  },
  button: {
    border: '3px solid',
    backgroundColor: '#FFFFFF',
    borderColor: '#00FF99',
    padding: 5,
    paddingLeft: 25,
    paddingRight: 25,
    alignSelf: 'flex-end',
    textTransform: 'none',
    fontSize: 20,
  },
  AiBLE: {
    font: 'Arial',
    fontWeight: 'bold',
  },
}));

function Scroll() {
    window.scrollTo(0, 0);
}

function AibleDrop(props) {

    var size = props.size;
    var colour = props.colour;
    var dir = props.direction;
    var text = props.text;

    var sx = 0;
    var sy = 0;
    var cx = size;
    var cy = size;

    switch (dir) {
        case "tr":
            sx = size;
            break;
        case "bl":
            sy = size;
            break;
        case "br":
            sx = size;
            sy = size;
            break;            
    }

    return <svg width={size*2} height={size*2}>
        <rect x = {sx} y= {sy} width={size} height={size} fill={colour}/>
        <circle cx = {cx} cy= {cy} r={size} fill={colour}/>
        <switch>
        <foreignObject x = {size*0.125} y={size*0.25} width={size*1.75} height={size*1.5}>
            <p style={{
                fontSize:size*0.250, 
                textAlign: 'center', 
                fontWeight: 'bold', 
                color:'white',

            }}>{text}</p>
        </foreignObject>
        </switch>
    </svg>

}

function DropLink(props) {
    var dropsize = 80;

    var child = props.child;

    return <Grid item container xs = {12} md = {3} lg = {2}>
        <Grid item container direction = "column" alignItems = "center" justify = "flex-start">
            <Link to={props.to} onClick={Scroll}>
            <AibleDrop size={dropsize} direction={props.direction} text={props.text} colour={props.colour}/>
            </Link>
            
            
            <div style={{textAlign: 'center', width:"95%", fontSize:18}}>
                {child}
            </div>
            
        </Grid>
    </Grid>

}

export default function HeaderHome() {
  var width = window.innerWidth;

  var direction = 'row';
  var stlogow = '5vw';
  var sttextw = '60%';
  var background = `url(${Ellipse})`;
  var mobile = 0;
  
  const colours = new Styles();

  if (width < 713) {
    direction = 'column';
    stlogow = '40%';
    sttextw = '80%';
    mobile = 12;
    background = "";
  }

  return (
    <Box boxShadow={3} style={{ backgroundColor: colours.charcoalGrey, padding: 20, width: '100%'}}>

        <Grid item xs={12} md={12} >

            <div style ={{textAlign: 'center', fontSize:35, paddingBottom:"20px"}}>Which one are you? - click and see how AiBLE helps</div>

        </Grid>

        <Grid item container xs={12} md={12} m='auto' alignItems="flex-start" justify="center" 
            style={{
                backgroundImage:background,
                backgroundSize:"100% 100%"
            }}
        >

            <DropLink direction="tl" text = "Job Candidate" colour={colours.green} to='/homecand' child={

                <div>
                    <p>AiBLE matches candidates to jobs for FREE</p>
                    <ul style={{textAlign: 'left', fontSize:16}}> 
                        <li>Directly message; or</li>
                        <li>Click external link to the job's owner</li>
                    </ul>
                </div>

            }/>

            <DropLink 
                direction = "br"
                text = "Employment Support Provider" 
                colour={colours.blue}
                to='/homeesp'
                child={
                <p>AiBLE connects support with people for FREE</p>
            }/>

            <DropLink
                direction="bl" 
                text = "Employer" 
                colour={colours.lightblue}
                to='/homeempl'
                child={
                    <p>AiBLE matches jobs to candidates for a small fee</p>
                }
            />

            <DropLink
                direction="tr" 
                text = "Training Provider" 
                colour = {colours.purple}
                to ='/homerto'
                child={
                    <p>AiBLE connects trainers with people for a small fee</p>
                }
            />
        </Grid>
            
        <Grid container direction={direction} justify="flex-end" alignItems="center">
            <Grid item container direction='column' justify="flex-end" alignItems="flex-end" xs={5}>
                <Grid item style={{paddingBottom:5}}>
                    <AppStoreButton type = 'google' width = '120px'/>
                </Grid>

                <Grid item>
                    <AppStoreButton type = 'apple' width = '120px'/>
                </Grid>
            </Grid>
        </Grid>

        </Box>
  );
}

