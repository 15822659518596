import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import forCandidates from "../assets/seeMore/for-candidates.jpg";
import forEmployers from "../assets/seeMore/for-employers.jpg";
import forEsps from "../assets/seeMore/for-esps.jpg";
import forRTOs from "../assets/seeMore/for-rtos.jpg";
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import CardMedia from '@material-ui/core/CardMedia';
import Styles from '../styles';

const colours = new Styles();
const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
        fontSize: 15,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop:5,
    },
    body:
    {
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop:5,
        fontSize: 15,
    },
    image:{
        height: 140,
        padding: 5,
    },
    green:{
        border: '3px solid',
        color:'white',
        backgroundColor: colours.appBarColor,
        borderColor: colours.green,
    },
    lightblue:{
        border: '3px solid',
        color:'white',
        backgroundColor: colours.appBarColor,
        borderColor: colours.lightblue,
    },
    blue: {
        border: '3px solid',
        color:'white',
        backgroundColor: colours.appBarColor,
        borderColor: colours.blue,
    },
    purple: {
        border: '3px solid',
        color:'white',
        backgroundColor: colours.appBarColor,
        borderColor: colours.purple,
    }
}));


function Scroll() {
    window.scrollTo(0, 0);
}

function SeeMore() {
    

    const styles = useStyles();

    return (
        <Grid container direction = "row" alignItems = 'center' justify = 'space-evenly'>
            <BoxElement button = {styles.green} link = "/candidateFAQ" title = "JOB CANDIDATES" backgroundColor = {colours.green} image = {forCandidates} body = "People looking for a job can use AiBLE to find their job match"/>
            <BoxElement button = {styles.lightblue} link = "/employerFAQ" title = "EMPLOYERS" backgroundColor = {colours.lightblue} image = {forEmployers} body = "Businesses searching for matched candidates"/>
            <BoxElement button = {styles.blue} link = "/espFAQ" title = "EMPLOYMENT SUPPORT PROVIDERS" backgroundColor = {colours.blue} image = {forEsps} body = "People who provide employment support"/>
            <BoxElement button = {styles.purple} link = "/rtoFAQ" title = "REGISTERED TRAINING ORGANISATIONS (RTOs)" backgroundColor = {colours.purple} image = {forRTOs} body = "Organisations that provide certified training"/>
        </Grid>
    );

}

function BoxElement(props) {

    const styles = useStyles();

    var width = window.innerWidth;
    var fontSize = 15;
    if (width < 713) {
        fontSize = 12;
    }

    return (
        <Grid item container direction = "column" justify = 'flex-end' xs = {8} md = {5} lg = {2} style = {{backgroundColor:props.backgroundColor,padding:5,height:340,color:"white"}}>

            <p className = {styles.title} style = {{fontSize:fontSize}}>{props.title}</p>
            <p className = {styles.body}>{props.body}</p>
            <div style={{flexGrow:1}}/>
            <CardMedia
                className = {styles.image}
                image = {props.image}
                style = {{display:'flex',justifyContent:"center"}}
            >   
                <Button className = {props.button} style = {{padding:5,alignSelf:"flex-end"}} variant="contained" component = {Link} to = {props.link} onClick={Scroll}>
                    See More
                </Button>
            
            </CardMedia>
 
        </Grid>
    );

}

export default SeeMore;