//import React, { useContext } from 'react';
//import { AppBar, Box } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//remove  these imports once changed
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import AppStoreButton from '../elements/AppStoreButton';
import { Button, ButtonGroup } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useAuthState, logout, useAuthDispatch } from '../Context';
import Styles from '../styles';

const colours = new Styles();

const useStyles = makeStyles((theme) => ({
  button: {
    border: '3px solid',
    backgroundColor: colours.appBarColor,
    borderColor: colours.green,
    color: "white",
    padding: 5,
    paddingLeft: 25,
    paddingRight: 25,
    alignSelf: 'flex-end',
    textTransform: 'none',
    fontSize: 20,
  },

}));

function WebApp() {
  const styles = useStyles();

  function openPopup() {
    window.open("https://app.aible.app/","AiBLE Job Search","width=350,height=600,resizable=0")
  }


  return (
    <div>
      <TopBar
        child={<PageHeader colour={colours.lightblue} title='AiBLE®: Job Search App' />}
      />
      <div
        style={{
          minHeight: 800,
          textAlign: 'center',
          paddingTop: 20,
          fontSize: 23,
        }}
      >
        <ButtonGroup
          size='large'
          orientation='vertical'
          variant='contained'
          aria-label='Login as'
          style={{ width: '20%', padding: 30, visibility: 'hidden' }}
        >
          <Button
            color='primary'
            component={Link}
            to='/signin'
            style={{ marginBottom: 20, borderRadius: 50 }}
          >
            Find me a job
          </Button>
          <Button
            color='secondary'
            component={Link}
            to='/signin'
            style={{ borderRadius: 50 }}
          >
            Employer Portal
          </Button>
        </ButtonGroup>
        <div>
          AiBLE is available on our website
        </div>
        <br/>
        <Button
          variant='contained'
          className={styles.button}
          onClick={openPopup}
        >
          CLICK HERE
        </Button>
        <div style ={{fontSize:11}}>(You may need to allow popups for this site in your browser)</div>
        <br/>
        <br/>
        <div>
          AiBLE is available to download from the AppStore and Google Play
          <br />
          <br />
          <Grid container direction="row" justify="center" spacing={5}>
            <Grid item>
            <AppStoreButton type = 'apple' height = '50px'/>
            </Grid>
            <Grid item>
            <AppStoreButton type = 'google' height = '50px'/>
            </Grid>
          </Grid>
          
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

export default WebApp;
