import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import { FormControl, FormLabel, FormControlLabel, TextField, Button, Grid, Checkbox, Dialog, DialogTitle } from '@material-ui/core';
import axios from 'axios';
import { makeStyles, styled } from '@material-ui/core/styles';
import Styles from '../styles';
const colours = new Styles();

const useStyles = makeStyles((theme) => ({
    input: {
        color: 'white'
    }
}));

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: colours.green,
    },
    '& label': {
      color: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: colours.green,
      },
      '&.Mui-focused fieldset': {
        borderColor: colours.green,
      },
    },
  });

function Support() {

    

    const required = ['fullname','email','description']
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const handleClickOpen = (fields) => {
        setOpen(true);
    };

    const handleClose = (value) => {
    setOpen(false);
    setFields([]);
    };

    const handleCloseError = () => {
        setOpenError(false);
    };

    const handleCloseSuccess = () => {
        setOpenSuccess(false);
    };

    const [state, setState] = React.useState({
        subscribe: false,
        fullname: {"error":false,"v":"",helptext:"",name:"Full Name"},
        email:{"error":false,"v":"",helptext:"",name:"Email"},
        organisation:{"error":false,"v":"",helptext:""},
        description:{"error":false,"v":"",helptext:"",name:"Description"}
    });

    const handleChange = (event,id) => {
        state[id].v = event.target.value; 
    }

    const handleCheckbox = (event) => {
        setState({...state,[event.target.name]:event.target.checked});
    }

    const submit = () => {
        
        const api = 'https://quarkus.aible.app';
        
        var validate = true;
        required.forEach(field => {
            if (state[field].v === "") {
                validate = false;
                fields.push(state[field].name);
            }
        })

        if (validate) {

            

            axios.post(api+'/api/public/support',{
                id: 0,
                uid: 0,
                description: state.description.v,
                email: {
                    name: state.email.v,
                    validated: false
                },
                fullname: state.fullname.v,
                organisation: state.organisation.v,
                created: new Date().toISOString(),
                subscribe: state.subscribe,
            }).then((response) => {
                if (response.status !== 500) {
                    setOpenSuccess(true);
                    setState({
                        subscribe: false,
                        fullname: {"error":false,"v":"",helptext:"",name:"Full Name"},
                        email:{"error":false,"v":"",helptext:"",name:"Email"},
                        organisation:{"error":false,"v":"",helptext:""},
                        description:{"error":false,"v":"",helptext:"",name:"Description"}
                    });
                } else {
                    setOpenError(true);
                }
            }, (error) => {
                setOpenError(true);
            });

        } else {
            handleClickOpen(fields);
        }

    }

    var width = window.innerWidth;
    var mobile = 1;
    var direction = "row";
    if (width < 713) {
        mobile = 0;
        direction = "column"
    }


    return (
        <div>
            <Dialog aria-labelledby="simple-dialog-title" open={open} onClose = {handleClose}>
                <DialogTitle id="simple-dialog-title">Missing Fields!</DialogTitle>
                {
                    fields.map((field) => <div style = {{paddingLeft:10,paddingRight:10}}>-{field}</div>)
                }
                <div style = {{paddingTop:5,paddingBottom:20,paddingLeft:10,paddingRight:10}}>
                    Please fill these fields in before proceeding
                </div>
            </Dialog>
            <Dialog aria-labelledby="simple-dialog-title" open={openError} onClose = {handleCloseError}>
                <DialogTitle>Error!</DialogTitle>
                <div style = {{paddingTop:5,paddingBottom:20,paddingLeft:10,paddingRight:10}}>
                    The server returned an error!
                </div>
            </Dialog>

            <Dialog aria-labelledby="simple-dialog-title" open={openSuccess} onClose = {handleCloseSuccess} style = {{textAlign:'center'}}>
                <div style = {{fontSize:20,padding:20}}>
                    <span style = {{fontWeight:"bold"}}>Thank you</span> <br/>
                    for your enquiry
                </div>
                <div style = {{padding:20}}>
                    An AiBLE team member
                    will contact you
                    within 24 hours
                </div>
            </Dialog>

            <TopBar 
            child = {<PageHeader colour = {colours.green} title = "CONTACT US"/>}/>
            <Grid container justify="center" direction = 'column' alignItems = 'center' style = {{minHeight:500,padding:20,paddingTop:40}}>
                <Grid item style = {{maxWidth:800}}>
                    <div style = {{textAlign:'center',paddingBottom:10}}>
                        Please fill out the form below or send us an email at <a class='link' href = "mailto: admin@aible.app">admin@aible.app</a>
                    </div>
                </Grid>
                <Grid item container justify="center" direction = 'column' alignItems = 'stretch' style = {{maxWidth:800}}>
                    <FormInput id = "fullname" state = {state} text = "Full Name*" onChange = {(e) => handleChange(e,"fullname")}/>
                    <FormInput id = "email" state = {state} text = "Email Address*" onChange = {(e) => handleChange(e,"email")}/>
                    <FormInput id = "organisation" state = {state} text = "Organisation" onChange = {(e) => handleChange(e,"organisation")}/>
                    <FormInput rows = {5} id = "description" state = {state} text = "Description*" onChange = {(e) => handleChange(e,"description")}/>
                    <FormLabel style = {{paddingBottom:5, color:"white"}}>* Required</FormLabel>
                    <FormControlLabel
                        control={<Checkbox color = 'primary' checked={state.subscribe} onChange={handleCheckbox} name="subscribe" />}
                        label="Tick here if you are willing to receive email updates from AiBLE"
                    />
                    <Grid item style = {{marginLeft:'auto',marginRight:'auto',paddingTop:10,paddingBottom:10}}>
                        <Button style = {{backgroundColor:colours.green,borderRadius: 200,paddingLeft:80,paddingRight:80}} variant = 'contained' onClick = {submit}>
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );

}


function FormInput(props) {
    const styles = useStyles();
    var variant = 'outlined';
    var multiline = false;
    if (props.rows !== undefined) {
        
        multiline = true;
    }

    return(
        <FormControl style = {{paddingBottom:20}}>
            <CssTextField 
                error = {props.state[props.id].error} 
                helperText={props.state[props.id].helptext} 
                onChange = {props.onChange}
                variant={variant} label={props.text} 
                multiline = {multiline}
                rows={props.rows} 
                InputProps={{
                    className:styles.input,
                }}
                id={props.id} 
                aria-describedby={props.id+'-helper'} />
        </FormControl>
    );  
}

export default Support;

/*

/api/public/support

{
    "uid" : 0,
    "description": "string",
    "email": {
      "name": "string",
      "validated": true
    },
    "fullname": "string",
    "organisation": "string"
  }

*/
