import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import { Grid } from '@material-ui/core';

function OurStory() {

    var width = window.innerWidth;

    var slideWidth = 900;

    if (width < 713) {
        slideWidth = window.innerWidth;
    }


    return (
        <div>
            <TopBar
                child={<PageHeader colour='#6699FF' title="OUR STORY" />} />
            <div style={{ minHeight: 400, textAlign: 'left', paddingTop: 20, color: "white"}}>
                <Grid container justify="center">
                    <Grid item>
                        <div style={{ maxWidth: slideWidth }}>
                            <p>
                                AiBLE is an idea that I’ve evolved using co-design – a design thinking method I’m passionate about having previously worked with expert, Steve Lennon. AiBLE is co-designed with my cousin, Rachel Breman who is now our Head of Research, and Karen Walker, the AiBLE Ambassador for Wise Employment. People I’ve known throughout my career have provided great feedback, especially the customer experience feedback from clients of Wise Employment and students of Specialist Schools around the country – to which I’m ever grateful for. AiBLE seems to be the culmination of everything I’ve learned over my career. Starting from my graphic design and business process reengineering days working at EDS in my early 20s, through to mentoring women recently whilst at Australia Post. I have seen discrimination against women so inherently ingrained that highly qualified and experienced women don’t apply for roles unless they’ve ticked all the boxes, unlike their male counterparts. I’ve known people who are perfect for roles that aren’t even considered because of the way they look, or the fact they have a disability – even though their disability means they are more talented at certain skills than others. I have met people who are “lost” when their jobs have been taken away due to technology replacing roles and industries changing over time. These “institutionalized” people are displaced and feel they aren’t qualified to do anything else than what they know.
                            </p>


                            <p>
                                All these scenarios made me think about new technologies such as “swiping right”, where people can sit there and be approached. Wouldn’t it be great if finding a job was like dating…instead of a man finding a woman, why not it be a job finding a person – we would create matching algorithms that actually work and help improve self confidence and self worth. Why not automate the process of skills matching to help those understand what’s possible, and make clear education pathways to help them know how to get there.
                            </p>

                            <p>
                                This brought us to the creation of “Job Match” which then morphed into “AiBLE” – a name that is synonymous to why we created it – we want to empower people to find accurately matched jobs maximising potential and improving quality of life.
                            </p>
                            <p style={{ textAlign: 'right' }}>
                                Narelle Priestley, Managing Director
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div >
    );

}



export default OurStory;