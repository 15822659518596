import { Grid } from '@material-ui/core';
import React from 'react';
import TopBar from '../elements/TopBar';


export default function ESPRTOApp(props) {

    var contentId = "web";

    return (
        <div>
            <TopBar
                child={
                    <div>
                        <Grid container direction ="row" justify = "center">
                            <Grid item>
                                <iframe
                                    style={{
                                        width:700,
                                        height:640,
                                        border: '1px solid grey'
                                    }}
                                    key={contentId}
                                    src = {"https://app.aible.app/#"+props.path}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                    
                } 
            />
        </div>
    );

}

