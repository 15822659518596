import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';

function WhyAible() {

    var width = window.innerWidth;
    var iframe = <iframe title="pdf" style={{ width: '100%', height: 700 }} src={process.env.PUBLIC_URL + '/__pdf/capabilityStatement.pdf'} />
    var mobile = 1;
    if (width < 713) {
        iframe = <div />
        mobile = 0.1;
    }

    return (
        <div>
            <TopBar
                child={<PageHeader colour='#6699FF' title="AiBLE BENEFITS" />} />
            <div style={{ minHeight: 800 * mobile, textAlign: 'center', padding: 80, paddingTop: 20 }}>
                <a class='link' rel="noopener noreferrer" href={process.env.PUBLIC_URL + '/__pdf/capabilityStatement.pdf'} target="_blank" alt="open in new tab">Open PDF in new tab</a>
                {iframe}
            </div>
        </div>
    );

}

export default WhyAible;