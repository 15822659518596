import React, { useState, useReducer } from 'react';

let user = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser'))
  : '';

let token = localStorage.getItem('token') ? localStorage.getItem('token') : '';

export const initialState = {
  user: '' || user,
  token: '' || token,
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      // Clear the local storage
      localStorage.setItem('currentUser', '');
      localStorage.setItem('token', '');
      return {
        ...initialState,
        loading: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        user: action.payload,
        token: action.payload.access_token,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        user: '',
        token: '',
      };

    case 'LOGIN_ERROR':
      console.log(`Reducer error ${action.error}`);
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
