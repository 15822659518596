
import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import FAQQuestions from '../elements/FAQQuestions';
import Styles from '../styles';

function FAQ(props) {

    var questions = props.questions;

    const title = props.title + " - FREQUENTLY ASKED QUESTIONS (FAQs)";
    const colour = props.colour;

    const colours = new Styles();

    return (
        <div style={{backgroundColor:colours.charcoalGrey}}>
            <TopBar
                child={<div><PageHeader colour={colour} title={title} /></div>}
            />
            <br />
            <FAQQuestions questions={questions} />
        </div>
    );
}







export default FAQ;