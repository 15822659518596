
import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';


function ComingSoon(props) {

    return (
        <div>
            <TopBar
            child = {<PageHeader colour = {props.colour} title = {props.title}/>}/>
    
            <br/>
            <div style = {{textAlign:"center",color:"white"}}>
                This page is under construction<br/><br/>Sorry about that!
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>
    );

}


export default ComingSoon;