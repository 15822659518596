import React, { useState, useEffect } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, InputLabel, FormControl, InputAdornment, IconButton, OutlinedInput  } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Styles from '../styles';

const colours = new Styles();

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: colours.green,
  },
  '& label': {
    color: 'grey',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: colours.green,
    },
    '&.Mui-focused fieldset': {
      borderColor: colours.green,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  jobTitle: {
    paddingBottom: 10,
    fontSize: 20,
  },
  jobRef: {
    paddingBottom: 5,
    fontSize: 13,
  },
  company: {
    paddingBottom: 10,
    fontSize: 15,
    fontWeight: 'bold',
  },
  desc: {
    fontSize: 15,
  },
  jobList: {
    minHeight: 500,
    color:'white'
  },
  margin: {
    margin: theme.spacing(1),
  },
  inputStyle: {
    color:"white"
  },
  pagination: {
    '& 	.MuiTablePagination-toolbar': {
      color: 'white',
    },
    '& .MuiTablePagination-selectIcon': {
      color:'white'
    }
  },
  textField: {
    width: '25ch',
    '& label.Mui-focused': {
      color: colours.green,
    },
    '& label': {
      color: 'grey',
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: colours.green,
      },
      '&.Mui-focused fieldset': {
        borderColor: colours.green,
      },
    },
    
  },
}));

const minioAccessKey = "AKIAIOSFODNN7EXAMPLE";
const minioSecret = "wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY";
const minioUrl = "minio.aible.app";
const Minio = require('minio');
const minioClient = new Minio.Client({
  endPoint: minioUrl,
  useSSl: true,
  accessKey:minioAccessKey,
  secretKey:minioSecret,
});

function Scroll() {
  window.scrollTo(0, 0);
}

function JobList() {

  const locations = [
    "AU","NZ","SG","GB","US"
  ]

  const radiusTypes = [
    "locality",
    "street_address",
    "route",
    "neighborhood"
  ]

  const [items, setItems] = useState('no response');
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [sendRequest, setSendRequest] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countryCode, setCountryCode] = useState("ALL");
  const [lng,setLng] = useState(0.0);
  const [lat,setLat] = useState(0.0);
  const [search, setSearch] = useState("");
  const [entries, setEntries] = useState([]);
  const [radius, setRadius] = useState(0.0);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  }

  const handleChangePage = (event, value) => {
    if (sendRequest === false) {
      setPage(value);
      setSendRequest(true);
    }
    
  };

  const handleChangePageScroll = (event, value) => {
    setPage(value);
    setSendRequest(true);
    Scroll();
  };

  const handleChangeRowsPerPage = (event) => {
    if (sendRequest === false) {
      setSendRequest(true);
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }
  };

  const searchJobs = (event) => {
    setPage(0);
    setSendRequest(true);
  }

  const keyPress = (event) => {
    if(event.keyCode === 13){
       searchJobs(event);
    }
 }

  const searchOnChange = (event) => {
    if (event.target.textContent === '') {
      searchVal = event.target.value;
      requestAuto();
    }
  };

  const requestSearch = (event,place) => {
    console.log(place);
    if (place !== null) {
      var placeId = place.place_id;
      console.log(placeId);
      if (placeId !== undefined) {
        setPage(0);
        setItems([]);
        setTotal(0);
        axios.get(
          "https://quarkus.aible.app/api/public/geocode/json?place_id="+placeId
        ).then(
          (response) => {
            if (response.data.results.length > 0) {

              var result = response.data.results[0];
              var rLat = result.geometry.location.lat;
              var rLng = result.geometry.location.lng;
              
              console.log("logging result")
              console.log(result);

              result.address_components.forEach(ac => {
                if (locations.includes(ac.short_name)) {
                  setCountryCode(ac.short_name);
                }
              });

              setRadius(0);
              result.types.forEach(type => {
                if (radiusTypes.includes(type)) {
                  setRadius(50.0);
                }
              })
              
              

              setLat(rLat);
              setLng(rLng);

              setSendRequest(true);

            }
          },
          (error) => {
            console.log(error);
          }
        )
      }

    } else {
      setCountryCode("ALL");
      setLat(0.0);
      setLng(0.0);
      setRadius(0.0);
      setSendRequest(true);
    }
    
  };

  const requestAuto = () => {
    var value = searchVal;
    if (value === undefined) value = '';

    if (value.length >= 3) {
      
      axios
        .get(
          "https://quarkus.aible.app/api/public/place/autocomplete/json",
          {
            params: {
              input:value,
              language:"en",
              types:"geocode",
              components:"country:au%7Ccountry:nz%7Ccountry:gb%7Ccountry:sg%7Ccountry:us"
            }
          }
        ).then(
          (response) => {
            setEntries(response.data.predictions);
          },
          (error) => {
            console.log(error);
          }
        );
    } else {

      setEntries([]);
    }
  };

  useEffect(() => {
    const api = 'https://quarkus.aible.app';

    

    if (sendRequest) {
      setItems('no response');
      axios
        .get(api + '/v1/jobad/search', {
          params: {
            jobTitle: search,
            sorts: 'sourceCode ASC,id ASC',
            pageIndex: page,
            pageSize: rowsPerPage,
            countryCode: countryCode,
            lat: lat,
            lng: lng,
            radius:radius
          },
        })

        .then(
          (response) => {
            console.log(response.request);
            setItems(response.data.items);
            setTotal(response.data.total);
            setSendRequest(false);
          },
          (error) => {
            console.log(error);
            setSendRequest(false);
          }
        );
    }
  }, [sendRequest, page, rowsPerPage, lat, lng, countryCode]);

  const styles = useStyles();
  

  var placeholder = <div></div>;
  var joblist = <div></div>;
  var searchVal = '';
  if (items === 'no response') {
      placeholder = <div className={styles.jobList} style={{ textAlign: 'center' }}>
        Loading, please wait...
        <br />
        <br />
        <br />
        If this does not disappear promptly, please refresh the page or try
        again later
      </div>
  } else if (items.length === 0) {
      placeholder = <div style={{textAlign: 'center' }}>No Jobs near here at this time!<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>;
  
    } else {
      joblist = <div>{items.map((item, id) => (
        <div
          style={{ justifyContent: 'center', alignItems: 'center' }}
          key={id}
        >
          <Job
            title={item.title}
            jobRef={item.jobref}
            time={item.fulltime}
            state={item.state}
            suburb={item.suburb}
            name={item.name}
            logo={item.logourl}
            keyId={id}
            advert={item.advert}
          />
        </div>
      ))}
      </div>
    }

    var width = window.innerWidth;
    var options = [10, 20, 50, 100];
    var direction = 'row';
    var mobile = 1;
    var autoWidth = 500;
    

    if (width < 713) {
      options = [];
      direction = 'column';
      mobile = 0;
      autoWidth = width;
    }

    return (
      <div className={styles.jobList}>
        <Grid
          container
          direction={direction}
          justify='center'
          alignItems='center'
        >
          <Grid item>
            <FormControl style={{ paddingRight: 50 * mobile }}>
              <Autocomplete 
                freeSolo
                options={entries}
                getOptionLabel={(option) => option.description}
                filterOptions={(options, state) => options}
                style={{ width: autoWidth}}
                onInputChange={searchOnChange}
                onChange={(event, newValue) => {
                  requestSearch(event, newValue)
                }}
                renderInput={(params) => (
                <CssTextField
                  {...params}
                  label={"Location"}
                  variant='outlined'
                  InputProps={{...params.InputProps, className:styles.inputStyle}}
                />
              )}
              />

              {/*<Select id='state' value={country} onChange={handleChangeState}>
                {Object.keys(map).map((loc, index) => (
                  <MenuItem value={index} key={index}>
                    {loc}
                  </MenuItem>
                ))}
                </Select>*/}
            </FormControl>
          </Grid>
          <Grid item>
            <TablePagination
              count={total}
              page={page}
              className={styles.pagination}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={options}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
          <Grid item>
            <FormControl className={clsx(styles.margin, styles.textField)} variant="outlined">
              <InputLabel 
                htmlFor="outlined-adornment-search" 
                
              >
                Title Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-search"
                value={search} 
                onChange={handleSearchChange}
                labelWidth={85}
                onKeyDown={keyPress}
                InputProps={{
                  className: styles.textField
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={searchJobs}
                      edge="end"
                    >
                      <Search/>
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

          </Grid>
        </Grid>
        <List style={{ justifyContent: 'center', alignItems: 'center' }}>
          {placeholder}
          {joblist}
        </List>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '10px',
          }}
        >
          <TablePagination
            rowsPerPageOptions={options}
            count={total}
            className={styles.pagination}
            page={page}
            onChangePage={handleChangePageScroll}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  
}

function Job(props) {
  const styles = useStyles();
  const key = props.keyId;

  const [logo, setLogo] = useState(props.logo);

  var suburb = props.suburb;
  var state = props.state;
  var time = props.time;
  if (time !== undefined) {
    time.replace(/,/g, ', ');
  }
  var advert = props.advert;

  var location = undefined;
  var url = undefined;

  useEffect(() => {

    if (logo !== undefined) {
      if (!logo.includes("jobactive") && !logo.includes("__img")) {

        try {
          minioClient.presignedGetObject("answers",logo, function(err, preUrl) {
            if (err) {
              //setLogo("https://www.aible.app/__img/aible-logo.png")
            } else {
              setLogo("https://image-proxy.aible.app/150x150,sc/"+preUrl);
            }
          });
        } catch (e) {
          //setLogo("https://www.aible.app/__img/aible-logo.png")
        }
      }
    } else {
      setLogo("https://image-proxy.aible.app/150x150,sc/"+logo);
    }
  },[]);

  
  
  var date = undefined;
  if (advert !== undefined) {
    url = advert.business_website;
    date = advert.date;
  }

  var jobrefLine = jobrefLine = <div>{props.jobRef}</div>;
  if (url !== undefined) {
    if (!url.includes('jobsearch')) {
      url = '';
      jobrefLine = <div>{props.jobRef}</div>;
    }
  }
  if (suburb === undefined) suburb = '';
  if (state === undefined) state = '';
  if (time === undefined) time = '';

  if (suburb !== '' && state !== '') {
    location = '- ' + suburb + ', ' + state;
  } else if (suburb === '' && state === '') {
    location = '';
  } else if (suburb !== '' || state !== '') {
    if (suburb !== '') location = '- ' + suburb;
    else location = '- ' + state;
  }

  if (time !== '') time = '- ' + time;

  if (date !== undefined) {
    var hour = date.slice(11, 13);
    var minute = date.slice(14, 16);

    var year = date.slice(0, 4);
    var month = date.slice(5, 7);
    var day = date.slice(8, 10);

    date =
      'Posted: ' + hour + ':' + minute + ' - ' + day + '/' + month + '/' + year;
  }

  var width = window.innerWidth;
  var direction = 'row';
  var padding = (width - 990) / 2;
  if (padding < 0) padding = 0;
  var justify = 'flex-start';
  var xs = 2;
  var logoPad = 20;
  if (width < 713) {
    direction = 'column';
    padding = 10;
    justify = 'center';
    xs = 10;
    logoPad = 0;
  }
  return (
    <ListItem key={key} style={{ paddingLeft: padding, paddingRight: padding }}>
      <Grid container direction='column'>
        <Grid
          container
          item
          direction={direction}
          justify={justify}
          alignItems='flex-start'
        >
          <Grid item xs={xs-1}>
            <img src={logo} alt='' style={{ width: 80 }} />
          </Grid>
          <Grid container item xs={10} direction='column' style={{paddingLeft:logoPad}}>
            <Grid item className={styles.jobTitle}>
              {props.title}
            </Grid>
            <Grid item className={styles.jobRef}>
              <br />
              {jobrefLine}
            </Grid>
            <Grid item className={styles.desc}>
              {time} <br />
              {location} <br />
              <div style={{ paddingTop: 5 }}>{date}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ paddingTop: 10 }}>
          <Divider />
        </Grid>
      </Grid>
    </ListItem>
  );
}

export default JobList;

/* old display for description

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

<Accordion style = {{boxShadow:'none',border:'1px solid rgba(0,0,0,.20)'}}>
    <AccordionSummary style = {{maxHeight: 20,}} expandIcon={<ExpandMoreIcon />}>
        <p style = {{fontWeight:"bold"}}>See Job Details</p>
    </AccordionSummary>
    <AccordionDetails>
        <p>{props.desc}</p>
    </AccordionDetails>
</Accordion>
*/
