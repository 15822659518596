import React, { useState, useEffect } from 'react';
import { Grid, Button, Divider } from '@material-ui/core';
import axios from 'axios';
import logo from '../../assets/aible-logo-no-text-transparent.svg';
import ProductModel from './ProductModel.js';
import versionJson from '../../JSONdata/version.json';

export default function ProductSelect(props) {

    

    const [products, setProducts] = useState([]);

    const [sendRequest, setSendRequest] = useState(true);
    const [test, setTest] = useState(false);
    const [versionData, setVersionData] = useState(<div/>)

    const toggleTest = () => {
        setTest(true);
        setVersionData(
        <div>
            <p>{versionJson.date}</p>
            <p>{versionJson.git}</p>
        </div>
        );
        setSendRequest(true);
    }

    useEffect(() => {

        var url = decodeURI(window.location.href.split("&baseurl=")[1].split("&country_code=")[0])+"/stripe/products?country_code="+props.countryCode;
        var authToken = props.authToken;

        if (sendRequest) {
            console.log("sending product request");
            axios.get(
                url,
                {
                    headers: {
                        "Authorization": "Bearer "+authToken,
                        "Content-Type":"application/json",
                        "Accept":"application/json"
                    }
                }
            ).then(
                (response) => {
                  if (response.status === 200) {
                    setSendRequest(false);
                    var productModels = [];
                    var prods = response.data.products.data;
                    var prics = response.data.prices;

                    prods.forEach(function (prod, index) {
                        prics.forEach(function (pric, index) {
                            if (prod.id === pric.product && (!prod.name.includes("Test") || test)) {

                                var product = new ProductModel(
                                    prod.name,
                                    pric.unitAmount, 
                                    prod.unitLabel,
                                    prod.description,
                                    prod.metadata.amount,
                                    prod.id,
                                    pric.id,
                                    pric.currency
                                );
                                productModels.push(product);
                            }
                        });

                    });

                    setProducts(productModels.reverse());
                }
                },
                (error) => {
                  console.log(error);
                  setSendRequest(false);
                }
            );
        }


    }, [sendRequest]);

    

    return (
        <div>
            <div>
                {products.map((item, id) => (
                    <Product key = {id} select = {props.select} product={item}/>
            ))}
            </div>
            {versionData}
            <span style={{color:'#2F2F35'}} onClick={toggleTest}> TEST </span>
        </div>
    );
}


function Product(props) {

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const handleClick = () => {
        
        props.select(props.product);
    }

    var cost = numberWithCommas(props.cost/100);

    return (
        <div onClick={handleClick} style={{paddingLeft:40,paddingRight:40,paddingTop:10,paddingBottom:10}}>
            <Grid container direction = "row" justify="space-between" alignItems="center">
                <Grid item>
                    {props.product.name}
                </Grid>
                <Grid item>
                    {props.product.description}
                </Grid>
            </Grid>
            <Divider/>
        </div>
    );
}