
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class ProductModel  {


    constructor(name, unitPrice, unitLabel, description, units, productId, priceId, currency) {
        this.unitPrice = unitPrice;
        this.unitLabel = unitLabel;
        this.productId = productId;
        this.priceId = priceId;
        this.units = units;
        this.description = (unitPrice/100).toString() + " " + currency.toUpperCase() +" per Credit";
        this.name = name;
        this.currency = currency;
        
        this.totalPrice = this.units*this.unitPrice;
    }

    toString() {
        return JSON.stringify(this);
    }

    

}

