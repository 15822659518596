import { useState, useEffect } from "react";

export default function useAudioPlayer() {
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        const audio = document.getElementById("audio");
        playing ? audio.play() : audio.pause();
    })
    return {
        playing,
        setPlaying
    }

}