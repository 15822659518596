import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import slideStyles from './carousel.min.css';

export default function SlideshowTextHome(props) {
  var items = props.items;
  var type = props.type;
  var interval = props.interval;

  return (
    <div>
      <Carousel
        interval={props.interval}
        showStatus={false}
        showArrows={true}
        showIndicators={false}
        showThumbs={false}
        style={slideStyles}
        autoPlay={true}
        infiniteLoop={true}
      >
        {items.map((item) => {
          switch (type) {
            case 'text':
              return <TextItem item={item} key={item} />;
            case 'story':
              return <StoryItem item={item} key={item} />;
            case 'labelledImage':
              return <LabelledImageItem item={item} key={item} />;
            case 'image':
              return <ImageItem item={item} key={item} />;
          }
        })}
      </Carousel>
    </div>
  );
}

function TextItem(props) {
  return <div style={{ textAlign: 'left', paddingLeft: 25, fontSize:"12wh" }}>{props.item}</div>;
}

function StoryItem(props) {
  return (
    <div style={{ padding: 50 }}>
      <div style={{ fontSize: 28, paddingBottom: 50 }}>{props.item.q}</div>
      <div style={{ fontStyle: 'italic', fontSize: 18 }}>- {props.item.a}</div>
    </div>
  );
}

function LabelledImageItem(props) {
  var width = window.innerWidth;
  var style = { fontSize: 25 };
  if (width < 713) {
    style = { fontSize: 15, height: '3em' };
  }
  return (
    <div>
      <p style={style}>{props.item.text}</p>
      <img alt='' src={props.item.url} style={{ width: 262 }} />
    </div>
  );
}

function ImageItem(props) {
  return (
    <div>
      <img alt='' src={props.item.url} />
    </div>
  );
}
