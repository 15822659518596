import React from 'react';
import { AppBar, Box } from '@material-ui/core';
import TopBar from '../elements/TopBar';

export default function TestAppFrame(props) {

    return (
        <div>
            <TopBar child = {
                <iframe src = "https://app.aible.app" title = "app" width = {430} height = {800} style = {{border:"solid #444444 2px",margin:'0 auto',display:'table'}}/>
            }/>
        </div>
    );

}


        
