import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import Grid from '@material-ui/core/Grid';
import bulletPoint from '../assets/aible-corp.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    button: {
      border: '3px solid',
      backgroundColor: '#FFFFFF',
      borderColor: '#7A1758',
      padding: 5,
      paddingLeft: 25,
      paddingRight: 25,
      alignSelf: 'flex-end',
      textTransform: 'none',
      fontSize: 20,
    },
  
  }));

export default function CorpPage(props) {

    const styles = useStyles();

    return (
        <div>
            <TopBar
                child={
                    <div>
                        <PageHeader colour={props.colour} title="WE UNDERSTAND YOU HAVE EXISTING PROCESSES IN PLACE FOR RECRUITMENT"/>
                        <br/>
                        <div style={{paddingLeft:50, width:"50%",marginLeft:"100%", marginRight:"100%", margin:"auto"}}>
                            <Grid container direction='column'>

                                <ListItem child={<div>AiBLE has created a simple solution for corporate customers. You can either use the AiBLE app directly to post jobs, or we can help.</div>}/>
                                <ListItem child={<div>At no cost, upload your job descriptions/job ads and review your matched candidates.</div>}/>
                                <ListItem child={<div>If you want to make contact with any AiBLE candidates, we will invoice your business $250AUD per job.</div>}/>
                                <Grid item>
                                    <Button 
                                        variant='contained'
                                        className={styles.button}
                                        component={Link}
                                        to='/corpAppRegister'
                                    >
                                        UPLOAD YOUR JOBS NOW
                                    </Button>
                                </Grid>
                                <Grid item style={{color: 'white',fontSize:18,paddingTop:20,paddingBottom:20}}>If you have already registered, login using your email and password</Grid>
                                <Grid item>
                                    <Button 
                                        variant='contained'
                                        className={styles.button}
                                        component={Link}
                                        to='/corpAppLogin'
                                    >
                                        LOGIN
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <br/>
                    </div>
                } 
            />
        </div>
    );

}


function ListItem(props) {
    return (
        <div style={{color: 'white',fontSize:18,paddingLeft:20,paddingBottom:30}}>
            <Grid item container direction = "row">
                <Grid item>
                    <img src = {bulletPoint} width = {35} alt = "" style = {{paddingRight:30}}/>
                </Grid>
                <Grid item xs={10}>
                    {props.child}
                </Grid>
            </Grid>
        </div>
    );
}