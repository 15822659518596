import React from 'react';
import TopBar from '../elements/TopBar';
import cta from '../assets/partners/cta.jpg';
import groomed from '../assets/partners/groomed.jpg';
import staffaustralia from '../assets/partners/staffaustralia.jpg';
import tdg from '../assets/partners/tdg.png';
import untapped from '../assets/partners/untapped.jpg';
//import wise from '../assets/partners/wise.png';
import PageHeader from '../elements/PageHeader';
import optus from '../assets/optus.png';
import bridge from '../assets/partners/bridgeworks.jpg';
import { Grid } from '@material-ui/core';

import Styles from '../styles';

const colours = new Styles();

function Partners() {

    var width = window.innerWidth
    var imgWidths = [280,280,240,240,240,240,240];

    return (
        <div>
            <TopBar child = {<PageHeader colour = {colours.lightblue} title = "OUR PARTNERS"/>}/>
            <Grid container direction = "column" alignItems = "center" style = {{padding:10}}>
                <Grid container item direction = "row" justify = "space-evenly" style = {{padding:20}}>
                    {/*
                    <Grid item>
                        <img src = {wise}alt = "The WISE Employment logo" width = {imgWidths[0]}/>
                    </Grid>
                    */}
                    <Grid item>
                        <img src = {staffaustralia} alt = "The Staff Australia logo" width = {imgWidths[1]}/>
                    </Grid>
                    
                    <Grid item>
                        <img src = {untapped} alt = "The Untapped logo" width = {imgWidths[6]}/>
                    </Grid>
                </Grid>
                <Grid container item direction = "row" justify = "space-evenly" style = {{padding:20}}>
                    <Grid item>
                        <img src = {cta} alt = "The Civil Training Australia logo" width = {imgWidths[2]}/>
                    </Grid>
                    <Grid item>
                        <img src = {tdg} alt = "The Traffic Diversions Group logo" width = {imgWidths[3]}/>
                    </Grid>
                </Grid>
                <Grid container item direction = "row" justify = "space-evenly" style = {{padding:20}}>
                    <Grid item>
                        <img src = {groomed} alt = "The Groomed to Go logo" width = {imgWidths[4]}/>
                    </Grid>
                    <Grid item>
                        <img src = {bridge} alt = "The bridgeworks logo" width = {imgWidths[5]}/>
                    </Grid>
                    <Grid item>
                        <img src = {optus} alt = "The Optus Future Makers logo" width = {imgWidths[6]}/>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default Partners;