import React from 'react';
import Typist from 'react-typist';
import TypistLoop from 'react-typist-loop'

function shuffle(array) {
    array.sort(() => Math.random() - 0.5);
}

function TypingElement(props) {
    var quotes = props.quotes;

    var width = window.innerWidth;
    var fontSize = 23;
    var height = "3em";
    if (width < 713) {
        fontSize = 18;
        height = "4em";
    }


    shuffle(quotes);

    return (
        <div style = {{fontSize:fontSize,height:height,padding:5,color:'white'}}>
            <TypistLoop interval = {5000}>
                {
                    quotes.map(text => <Typist cursor = {{show: false}} key={text} startDelay={500}><div>{text}</div></Typist>)
                }
            </TypistLoop>
        </div>
    );

}

export default TypingElement;