
import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import Grid from '@material-ui/core/Grid';
import logo from '../assets/aible-logo-no-slogan.svg';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Styles from '../styles';

const colours = new Styles();

export default function TrainingHome(props) {
    const colour = props.colour;
    const border = 'solid '+colour+' 5px';

    

    return (
        <div >
            <TopBar
                child = {
                    <div style = {{padding:0}}>
                        <PageHeader colour = {colour} title = "AiBLE TRAINING - We encourage people to be disability aware"/>
                        <br/>
                        <div style = {{padding:30,backgroundColor:colours.charcoalGrey,justifySelf:"center",margin:"auto",fontSize:20,color:'white'}}>
                            <Grid container direction = "column" alignItems = "center">
                                <div style = {{paddingBottom:60,fontSize:22,maxWidth:500}}>
                                    You can do our training here or you can login and do the
                                    training so your test result appears in your AiBLE profile.
                                </div>
                                <div style = {{padding:10}}>
                                    <Button component = {Link} to = "/trainquizeveryone" style = {{fontSize:19,color:'white',backgroundColor:colour,minWidth:500,paddingTop:20,paddingBottom:20}}>
                                        Disability Awareness – For Everyone
                                    </Button>
                                </div>
                                <div style = {{padding:10}}>
                                    <Button component = {Link} to = "/trainquizemployer" style = {{fontSize:19,color:'white',backgroundColor:"#9966FF",minWidth:500,paddingTop:20,paddingBottom:20}}>
                                        Disability Awareness – For Employers
                                    </Button>
                                </div>
                            </Grid>
                        </div>
                    </div>
                }
            />
        </div>
    );
}