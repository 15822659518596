import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import Grid from '@material-ui/core/Grid';
import bulletPoint from '../assets/aible-esp.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    button: {
      border: '3px solid',
      backgroundColor: '#FFFFFF',
      borderColor: '#A7C4FF',
      padding: 5,
      paddingLeft: 25,
      paddingRight: 25,
      alignSelf: 'flex-end',
      textTransform: 'none',
      fontSize: 20,
    },
  
  }));

export default function ESPPage(props) {

    const styles = useStyles();

    return (
        <div>
            <TopBar
                child={
                    <div>
                        <PageHeader colour={props.colour} title="REGISTER AND CONNECT WITH THE AiBLE COMMUNITY"/>
                        <br/>
                        <div style={{paddingLeft:50, width:"50%",marginLeft:"100%", marginRight:"100%", margin:"auto"}}>
                            <Grid container direction='column'>

                                <ListItem child={
                                    <div>The benefits of being a registered AiBLE ESP (Employment Support Provider) are:<br/>
                                             - Connection with candidates who require your support<br/>
                                             - Connection with employers who value diversity and inclusion</div>}/>
                                <ListItem child={<div>AiBLE job candidates and employers can choose an ESP from the list of registered ESPs to receive support. AiBLE will send an email with your details, and you will receive an email with the employers' contact and job details.</div>}/>
                                <ListItem child={<div>Once registered you can login using your email and password to make changes</div>}/>
                                <Grid item>
                                    <Button 
                                        variant='contained'
                                        className={styles.button}
                                        component={Link}
                                        to='/espAppRegister'
                                    >
                                        REGISTER FOR FREE
                                    </Button>
                                </Grid>
                                <Grid item style={{color: 'white',fontSize:18,paddingTop:20,paddingBottom:20}}>If you have already registered, login using your email and password</Grid>
                                <Grid item>
                                    <Button 
                                        variant='contained'
                                        className={styles.button}
                                        component={Link}
                                        to='/espAppLogin'
                                    >
                                        LOGIN
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <br/>
                    </div>
                } 
            />
        </div>
    );

}


function ListItem(props) {
    return (
        <div style={{color: 'white',fontSize:18,paddingLeft:20,paddingBottom:30}}>
            <Grid item container direction = "row">
                <Grid item>
                    <img src = {bulletPoint} width = {35} alt = "" style = {{paddingRight:30}}/>
                </Grid>
                <Grid item xs={10}>
                    {props.child}
                </Grid>
            </Grid>
        </div>
    );
}