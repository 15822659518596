
import React from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';



export default function FAQQuestions(props) {

    var questions = props.questions;
    return (
        <Grid container direction = 'column' justify = 'center' alignItems = 'center' style = {{paddingBottom:20}}>
            {
                questions.map( question => <Question q={question.q} a = {question.a}/> )
            }

            <Question q = "Who can I contact for more information or questions?" a = "<p>If you need to connect with our friendly team at AiBLE, please send us an email at:<br/><a class='link' href = 'mailto:admin@aible.app'>admin@aible.app</a><br/>We are  located in Melbourne, Victoria</p>"/>

        </Grid>
    );
}

function CreateMarkup(text) {
    return {__html: text}
}


function Question(props) {

    return (
        <Grid item style = {{padding:5,flexGrow:1,maxWidth:950,width:'100%',textAlign: 'left'}}>
            <Accordion style = {{backgroundColor:"#CCFFFF"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <p style = {{fontWeight:"bold"}}>{props.q}</p>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <div dangerouslySetInnerHTML={CreateMarkup(props.a)}/>
                    </div>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}