import React, {useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PageSelector from '../elements/stripeGateway/PageSelector.js';
import { Grid } from '@material-ui/core';

import Styles from '../styles';


import text from '../assets/aible-text.png';

const testKey = "pk_test_mFWC9X5mKbnFqV6DwB2YObdN";
const liveKey = "pk_live_Tb9pGXD0vWIRYD1XqbISySm4";

const stripePromise = loadStripe(liveKey);




export default function PaymentPage() {

    const colours = new Styles();

    const split = window.location.href.includes("#/paymentTest?token=");
    var authToken = window.location.href;
    var countryCode = window.location.href;
    
    if (!split) {
        window.location.href = window.location.href.split("#")[0];
    } else {
        authToken = authToken.split("#/paymentTest?token=")[1].split("&baseurl=")[0];
        countryCode = countryCode.split("&country_code=")[1];
        if (countryCode.trim().length === 0) {
            countryCode = "AU";
        }
    }

    return (
        <div style={{backgroundColor:colours.charcoalGrey,height:"100vh"}}>
                <Grid item container direction = "column">
                    <Grid item>
                        <div style={{backgroundColor:colours.appBarColor,color:"white",padding:10}}>
                            {/*<img src = {text} width={318/2} height = {64/2}/>*/}
                            Each job credit will expire on June 30 2022
                        </div>
                    </Grid>
                    <Grid item>
                        <Elements stripe={stripePromise}>
                            <PageSelector authToken = {authToken} countryCode = {countryCode}/>
                        </Elements>
                    </Grid>
                </Grid>
            </div>
    );
    
}