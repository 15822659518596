import React from 'react';
import TopBar from '../elements/TopBar';
import JobList from '../elements/Joblist';
import PageHeader from '../elements/PageHeader';


function Jobs() {
    return <div>
        <TopBar child={< PageHeader
            title="LOGIN TO AiBLE AND SEE YOUR % MATCH AGAINST EVERY JOB – AiBLE helps you understand career possibilities" />} /> <br />
        <JobList />
    </div>;
}

export default Jobs;