import React from 'react';
import SearchElement from '../elements/SearchElement';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import Styles from '../styles';

const colours = new Styles();

export default function Search() {

    return (
        <div>
        <TopBar 
        child = {<PageHeader colour = {colours.green} title = "CAREER SERVICES"/>}/>
            <div style = {{minHeight:800,backgroundColor:colours.charcoalGrey,color:"white"}}><SearchElement/></div>
        </div>
    );

}