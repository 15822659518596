import { Button } from "@material-ui/core";
import React from "react";
import { VolumeUp, VolumeMute } from "@material-ui/icons";
import useAudioPlayer from '../customHooks/useAudioPlayer';

export default function AudioPlayer(props) {
    const {src} = props;
    const {playing, setPlaying} = useAudioPlayer();

    const handleClick = () => {
        setPlaying(!playing);
    }

    return (
        <div>
            <audio id = "audio">
                <source src = {src}/>
                Your browser does not support the <code>audio</code> element.
            </audio>
            <Button onClick={handleClick} style = {{color:"white"}}>
                {playing ? <VolumeUp fontSize = "large"/> : <VolumeMute fontSize = "large"/>}
            </Button>
        </div>
    );

}
