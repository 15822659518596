import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import Grid from '@material-ui/core/Grid';
import bulletPoint from '../assets/aible-esp.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    button: {
      border: '3px solid',
      backgroundColor: '#FFFFFF',
      borderColor: '#A7C4FF',
      padding: 5,
      paddingLeft: 25,
      paddingRight: 25,
      alignSelf: 'flex-end',
      textTransform: 'none',
      fontSize: 20,
    },
  
  }));

export default function CharityPage(props) {

    const styles = useStyles();

    return (
        <div>
            <TopBar
                child={
                    <div>
                        <PageHeader colour={props.colour} title="AiBLE WILL DONATE 20% OF ALL REFERRAL SPEND TO YOUR CHARITY"/>
                        <br/>
                        <div style={{paddingLeft:50, width:"50%",marginLeft:"100%", marginRight:"100%", margin:"auto"}}>
                            <Grid container direction='column'>

                                <ListItem child={<div>AiBLE is about supporting the community. When you refer a business to use AiBLE, 20% of all their transactions for a 12-month period will be automatically donated to your charity.</div>}/>
                                <ListItem child={<div>For example: if just one business buys a 50 job bundle each monthyou will raise $6,000 in one year<br/><br/>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item>
                                            1 job bundle<br/>
                                            10 job bundle<br/>
                                            20 job bundle<br/>
                                            50 job bundle
                                        </Grid>
                                        <Grid item>
                                            = $250 per credit<br/>
                                            = $150 per credit<br/>
                                            = $100 per credit<br/>
                                            = $50 per credit
                                        </Grid>
                                    </Grid>
                                </div>}/>
                                <Grid item>
                                    <Button 
                                        variant='contained'
                                        className={styles.button}
                                        component={Link}
                                        to='/charityAppRegister'
                                    >
                                        REGISTER FOR FREE
                                    </Button>
                                </Grid>
                                <Grid item style={{color: 'white',fontSize:18,paddingTop:20,paddingBottom:20}}>If you have already registered, login using your email and password</Grid>
                                <Grid item>
                                    <Button 
                                        variant='contained'
                                        className={styles.button}
                                        component={Link}
                                        to='/charityAppLogin'
                                    >
                                        LOGIN
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <br/>
                    </div>
                } 
            />
        </div>
    );

}


function ListItem(props) {
    return (
        <div style={{color: 'white',fontSize:18,paddingLeft:20,paddingBottom:30}}>
            <Grid item container direction = "row">
                <Grid item>
                    <img src = {bulletPoint} width = {35} alt = "" style = {{paddingRight:30}}/>
                </Grid>
                <Grid item xs={10}>
                    {props.child}
                </Grid>
            </Grid>
        </div>
    );
}