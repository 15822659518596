import React from 'react';
import TopBar from '../elements/TopBar';
import PageHeader from '../elements/PageHeader';
import roadtolaunch from '../assets/roadtolaunch.svg'
import { Grid } from '@material-ui/core';
import Styles from '../styles';

function OurStory() {

    var width = window.innerWidth;

    var slideWidth = 1900;

    if (width < 713) {
        slideWidth = window.innerWidth;
    }

    const colours = new Styles();
    return (
        <div>
            <TopBar child={<PageHeader colour={colours.green} title="ROAD TO LAUNCH" />} />
            <div style = {{backgroundColor:'black'}}>
                <Grid container justify = "center" style = {{backgroundColor:"white"}}>
                    <img src = {roadtolaunch} alt = "Roadmap" style = {{maxWidth:1300,width:"80%",paddingTop:5}}/>
                </Grid>
                <br/>
            </div>
        </div >
    );

}



export default OurStory;