import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TopBarWeb from './TopBarWeb';
import TopBarMobile from './TopBarMobile';
import Styles from '../styles';
import { Link } from 'react-router-dom';
import logo from '../assets/logo-block-sqr-full-colour-trans-bg-test.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1,
  },
  line: {
    borderBottom: "4px solid white",
  },
  text: {
    color: '#FFFFFF',
    //fontSize: 20,
    paddingTop: 10,
    textTransform: 'none',
    paddingLeft: 10,
    paddingRight: 20,
    textDecoration: 'none',
    font: "calibri",
  },
  greenText: {
    color: "#68B770",
    fontSize: 20,
    paddingTop: 10,
    textTransform: 'none',
    paddingLeft: 10,
    paddingRight: 20,
    textDecoration: 'none',
    font: "calibri",
  },
  bar : {
    flexGrow: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
  drawer: {
    background: '#2F2F35'
  },
  accordion: {
    backgroundColor:'#2F2F35',
  }


}));

const colours = new Styles();

function TopBar(props) {


  const styles = useStyles();

  const [drawer, setDrawer] = React.useState(false);
  
  const colours = new Styles();

  var width = window.innerWidth
  var header = <TopBarWeb/>;
  if (width < 713) {
    header = <TopBarMobile/>;
  }

  const toggleDrawer = (open) => (event) => {
    setDrawer(open);
  }




  return (
    <div className="App">
      
        <AppBar style = {{background: colours.appBarColor, color:"white"}} position = "static">
          <Grid className={styles.root} container direction="row" alignItems="flex-start" justify = "space-evenly">
            <Grid item xs = {12}>
              <Grid container item direction = "column" justify = "space-evenly" alignItems = "center">
              <Grid
                container
                direction='row'
                justify='space-evenly'
                alignItems='flex-end'
              >
                <img src={logo} alt='' width={100} style={{ padding: 10 }} />
                <div className={styles.bar}/>
                <Button
                  className={styles.text}
                  onClick={toggleDrawer(true)}
                  style={{fontSize:20}}
                  endIcon={<MenuIcon />}
                >
                  Menu
                </Button>
                <Button
                  className={styles.greenText}
                  component={Link}
                  to={'/app'}
                >
                  Login
                </Button>
                
                
              </Grid>
                <Grid item style={{width:"100%"}}>
                  { props.child }

                </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </AppBar>

        <Drawer
          anchor={'left'}
          open={drawer}
          onClose={toggleDrawer(false)}
          classes={{paper:styles.drawer}}
        >
          <List>
            <ListItem>
            <img src={logo} alt='' width={100} style={{ padding: 10 }} />
            </ListItem>
            <MenuItem label="Home" to='/'/>
            <MenuItem label="Jobs" to='/jobs'/>
            <MenuItem label="Our Partners" to='/partners'/>
            <AccordionItem 
              label="Why AiBLE"
              children={[
                {label:"Our Story", to:"/ourstory"},
                {label:"Meet the Team", to:"/meettheteam"},
                {label:"AiBLE Benefits", to:"/why"},
                {label:"One Page Brochure", to:"/brochure"},
                {label:"Customer Stories", to:"/stories"}

              ]}
            />
            <MenuItem label="Career Services" to='/search'/>
            <MenuItem label="AiBLE Training" to='/trainhome'/>
            <MenuItem label="Support Providers" to='/espportal'/>
            <MenuItem label="Corporate Customers" to ='/corpportal'/>
            <MenuItem label="Charities" to='/charityportal'/>
            <MenuItem label="Login" style={styles.greenText} to ='/app'/>
          </List>
          
        </Drawer>
        
    </div>
  );
}

function MenuItem(props) {

  const styles = useStyles();

  var style = styles.text;

  if (props.style !== undefined) {
    style = props.style
  }

  return <div>
    <ListItem component = {Link} to={props.to}>
        <ListItemText
          
          className={style}
          primary={props.label}
        />
    </ListItem>
    <Divider/>
    
    </div>

}

function AccordionItem(props) {

  const styles = useStyles();


  return <div><Accordion
    elevation={0}
    square={true}
    
  >
    <AccordionSummary
    className={styles.accordion}
      expandIcon={<ExpandMoreIcon/>}
    >

      <div className={styles.text}>{props.label}</div>

    </AccordionSummary>
    <AccordionDetails className={styles.accordion}>
      <List>
      {props.children.map((item) => (
        <MenuItem label={item.label} to={item.to}/>
      ))}
      </List>
    </AccordionDetails>
  </Accordion>
  <Divider/>
  </div>
  

}

export default TopBar;
