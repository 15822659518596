import React from 'react';
import Grid from '@material-ui/core/Grid';
import logo from '../assets/logo-motif-navy-bg.png';
import Slideshow from './Slideshow';
import Styles from '../styles';

function Quotes() {

    const colours = new Styles();

    return (
        <Grid container direction = "column" alignItems = 'center' m="auto" style = {{backgroundColor:colours.charcoalGrey,color:"white",paddingTop:30,paddingBottom:30,paddingLeft:20,paddingRight:20}}>
            <Grid item>
                <img src = {logo} alt = "" width = '200'/>
            </Grid>
            <Grid item xs = {8}>
                <Slideshow
                    type = "labelledImage"
                    showIndicators = {false}
                    items = {[
                        {text:"AiBLE matches you with the right job.",url:require("../assets/slideshowSection/free.jpg")},
                        {text:"Find a job using AiBLE. Your privacy is protected as you are anonymous.",url:require("../assets/slideshowSection/data.jpg")},
                        {text:"Use AiBLE’s Job Check and Qual Check Services to understand career paths.",url:require("../assets/slideshowSection/career.jpg")},
                        {text:"Search to find people to improve workplace diversity.",url:require("../assets/slideshowSection/diversity.jpg")},
                        {text:"People with disability are included.",url:require("../assets/slideshowSection/capable.jpg")},
                        {text:"People with disability are included as AiBLE matches on abilities.",url:require("../assets/slideshowSection/disa.jpg")},

                    ]}
                />
            </Grid>
        </Grid>
    );

}

export default Quotes;